<template>
  <div class="e-card-content ab">
    <!--
    <div &lt;!&ndash;class="content-flex-column"&ndash;&gt;>
-->
    <slot />
    <!--
    <div>
    </div>
-->
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>




@media (max-width: 1025px) {
        .e-card .e-card-content {
            padding: 0 6px 12px 6px;
            //background: yellow;
        }
}

.content-flex-row {
  /*
  justify-content: center;
  */
  display: flex;
  /*
  align-items: center;
  */
  flex-direction: row;
  column-gap: 1em;
  flex-wrap: wrap;
}

.content-flex-column {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1em;
  flex-wrap: wrap;
}

</style>
