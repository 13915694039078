import {Api} from "@/shared/services/api";
import OrderHead from "@/shared/model/orderHead";
import OrderItem from "@/shared/model/orderItem";
import Payment from "@/shared/model/Payment";
import OrderItemToInsurance from "@/shared/model/OrderItemToInsurance";
import Person from "@/shared/model/person";

export class ApiOrder extends Api {
  async orderLoad(
    id: string
  ): Promise<{
    orderHead: OrderHead;
    orderItems: OrderItem[],
    orderItemToInsurances: OrderItemToInsurance[]
    payment?: Payment
  } | undefined> {
    const resp = await this.post("OrderLoadRequest", {
      orderId: id,
    });
    if (resp.successful && resp.orderHead) {
      return {
        orderHead: resp.orderHead,
        orderItems: resp.orderItems,
        orderItemToInsurances: resp.orderItemToInsurance,
        payment: resp.payment
      };
    }
  }


  async orderInitPrintExport(id?: string, currentYear?: boolean): Promise<string | undefined> {
    if (!id) {
      return undefined
    }
    const resp = await this.post("InitPrintExportBlankRequest", {
      orderId: id, currentYear: currentYear
    });
    if (resp.successful && resp.id) {
      return resp.id;
    }
  }


  async orderInitPdf(id?: string): Promise<string | undefined> {
    if (!id) {
      return undefined
    }
    const resp = await this.post("OrderInitPdfRequest", {
      orderId: id,
    });
    if (resp.successful && resp.id) {
      return resp.id;
    }
  }

  async shipping(
    id: string
  ): Promise<boolean | undefined> {
    const resp = await this.post("OrderShippingRequest", {
      orderId: id,
    });
    return resp.successful;
  }

  async delivery(
    id: string
  ): Promise<boolean | undefined> {
    const resp = await this.post("OrderDeliveryRequest", {
      orderId: id,
    });
    return resp.successful;
  }

  async initExportExcel(id?: string): Promise<string | undefined> {
    if (!id) {
      return undefined
    }
    const resp = await this.post("InitExportExcel", {
      id: id,
    });
    if (resp.successful && resp.id) {
      return resp.id;
    }
  }

  async payRequest(payment: Payment): Promise<boolean | undefined> {
    {
      const resp = await this.post("OrderPayRequest", {
        payment: {
          $type: payment.paymentTypeId,
          orderId: payment.orderId,
          date: payment.date,
          paymentTypeId: payment.paymentTypeId,
          bank: payment.bank,
          memo: payment.memo,
        }
      });
      return resp.successful;
    }
  }

  //payment Delete

  async payRemove(id: string): Promise<boolean | undefined> {
    const resp = await this.post("OrderPayRemoveRequest", {
      id: id,
    });
    return resp.successful;
  }

  async orderPayment(id: string): Promise<boolean | undefined> {
    const resp = await this.post("OrderPaymentRequest", {
      id: id,
    });
    return resp.successful;
  }

  async personList(

  ): Promise<Person[] | undefined> {
    const resp = await this.post("OrderPersonListRequest",undefined);
    if (resp.successful && resp.items) {
      return resp.items
    }
  }


}

const apiOrder = new ApiOrder();

export default apiOrder;
