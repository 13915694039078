import {Api} from "@/shared/services/api";
import InsuranceCancelation from "@/shared/model/InsuranceCancelation";


export class ApiInsuranceCancelation extends Api {
    async load(id: string): Promise<{ insuranceCancelation: InsuranceCancelation } | undefined> {
        const request = {
            id: id,
        };
        const resp = await this.post("/InsuranceCancelationLoadRequest", request);
        if (resp.successful && resp.insuranceCancelation) {
            return {insuranceCancelation: resp.insuranceCancelation};
        }
        return undefined;
    }

    async   approve(text: string,ids: string[]): Promise< boolean| undefined> {
        const request = {
            text: text,
            ids: ids,
        };
        const resp = await this.post("/InsuranceCancelationAllowRequest", request);
          return resp.successful;
    }

    async   reject(text: string,ids: string[]): Promise< boolean| undefined> {
        const request = {
            text: text,
            ids: ids,
        };
        const resp = await this.post("/InsuranceCancelationRejectRequest", request);
          return resp.successful;
    }

    //onCreateInvoice
    async   createInvoice(text: string,ids: string[]): Promise< boolean| undefined> {
        const request = {
            text: text,
            ids: ids,
        };
        const resp = await this.post("/InsuranceCancelationCreateInvoiceRequest", request);
          return resp.successful;
    }

}


const apiInsuranceCancelation = new ApiInsuranceCancelation();

export default apiInsuranceCancelation;