import { ColumnModel } from "@syncfusion/ej2-grids/src/grid/models/column";

export const DefaultColumn: ColumnModel = {
  autoFit: true,
};

export const NumberColumn: ColumnModel = {
  ...DefaultColumn,
  format: "n",
  type: "number",
};

export const MoneyColumn: ColumnModel = {
  ...DefaultColumn,

  type: "number",
  format: {
    format: "C2",
    useGrouping: true,
    currency: "EUR",
  },

  textAlign: "Right",
};

export const DateTimeColumn: ColumnModel = {
  ...DefaultColumn,
  type: "dateTime",
  format: "dd.MM.yyyy HH:mm:ss",
  filter: { type: "CheckBox" },
};

export const DateColumn: ColumnModel = {
  ...DateTimeColumn,
  type: "datetime",
  format: "dd.MM.yyyy",
};

export const BooleanColumn: ColumnModel = {
  ...DefaultColumn,
  displayAsCheckBox: true,
};
