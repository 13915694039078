export interface ProgressInfo {
  id: string | null
  caption: string | null
  text: string | null
  statusText: string | null
  maxProgress: number | null
  progress: number | null
  startDate?: Date
  finishDate?: Date
  resultMessage: string | null
  resultId?: string
  resultInteger?: number
  successful: boolean | null
  groupId: string | null
  groupDescription: string | null
}

export const emptyProgressInfo:ProgressInfo = { id: null, caption: null, text: null, statusText: null, maxProgress: 0, progress: 0, resultMessage: null, successful: null, groupId: null, groupDescription: null, finishDate: undefined, startDate: undefined }
