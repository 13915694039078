import HistoryDetails from "@/shared/model/HistoryDetails";

export default class HistoryDetailsOrderItemToInsuranceInfo extends HistoryDetails {
  id?: string
  orderItemId?: string
  additionRefId?: string
  purchaseShortPrice?: number
  grossPrice?: number
  tax?: number
  taxAmount?: number
  shortPrice?: number
  amount?: number
  personId?: string
  supplierId?: string
  personName?: string
  supplierName?: string
  orderNumber?: string
  orderId?: string
  orderedDate?: Date
}

