export default class GdvAccess {
  id? :string
  description? :string
  address? :string
  userName? :string
  mode? :string
  communicationPartnerIdentifer? :string
  loginToken? :string
  logingTokenValidityDate? :Date
  lastLoginDate? :Date
  accountBlocked? :number
  pinError? :number
  pinErrorAllowed? :number
  accountBlockedText? :string
  active? :boolean
  pinValidity? :number
  statusText? :string
  briefkasten? :boolean
  transferBriefkastenIds? :string
}
