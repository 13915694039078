import {RouteRecordRaw} from "vue-router";
import SearchPage from "@/modules/LossAdjuster/pages/SearchPage.vue";
import BordereauPage from "@/modules/LossAdjuster/pages/BordereauPage.vue";

const lossAdjusterRoutes: Array<RouteRecordRaw> = [{
  path: "/lossadjuster",
  children: [
    {path: "search", component: SearchPage},
    {path: "bordereau", component: BordereauPage},

  ]
}];

export default lossAdjusterRoutes;
