import {RouteRecordRaw} from "vue-router";
import VehicleRegistrationListPage from "@/modules/VehicleRegistration/pages/VehicleRegistrationListPage.vue";
import ZulassungsDetailsPage from "@/modules/VehicleRegistration/pages/VehicleRegistrationDetailsPage.vue";

const vehicleregistrationRoutes: Array<RouteRecordRaw> = [
  {
    path: "/vehicleregistration/:type",
    component: VehicleRegistrationListPage
  },
  {
    path: "/vehicleregistration/details/:id?",
    component: ZulassungsDetailsPage,
    props: true,

  }
];

export default vehicleregistrationRoutes;
