export default class PersonDetails {
  private _values: any;
  constructor(values: any) {
    this._values = values;
  }

  getValues() {
    return this._values;
  }

  getValue(key: string) {
    if (this._values) {
      return this._values[key];
    }
    return undefined;
  }
  getNumberValue(key: string): number | undefined {
    if (this._values) {
      const s = this._values[key];
      if (s) return Number(s.replace(",", "."));
    }
    return undefined;
  }
  public getBooleanValue(key: string): boolean {
    if (this._values) {
      const s = this._values[key];
      if (s) return s?.toLowerCase() === "true";
    }
    return false;
  }

  public getBooleanWithDefaultValue(key: string,defaultValue=false): boolean {
    if (this._values) {
      const s = this._values[key];
      if (s !== undefined) return s?.toLowerCase() === "true";
    }
    return defaultValue;
  }



  setValue(key: string, value?: string) {
    if (this._values) {
      this._values[key] = value;
    }
  }
  setNumberValue(key: string, value: number) {
    if (this._values) {
      this._values[key] = value.toString().replace(",", "").replace(".", ",");
    }
  }
  setBooleanValue(key: string, value: boolean) {
    if (this._values) {
      this._values[key] = value ? "True" : "False";
    }
  }

  setDateValue(key: string, value: Date | undefined) {
    if(!value) return;
    if (this._values) {
      this._values[key] = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate();
    }
  }
}
