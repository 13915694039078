import {Api} from "@/shared/services/api";
import axios, {AxiosRequestConfig} from "axios";

export class ApiFile extends Api {
  async downloadFile(id: string, filename?: string) {
    try {
      const url = process.env.BASE_URL + "file/" + id;
      //    process.env.BASE_URL + 'bs/FileUtility/UploadTempFile'

      const t: AxiosRequestConfig = {
        ...this.getConfigApi(),
        url: url,
        method: "GET",
        responseType: "blob",
      };

      const resp = await axios.get("file/" + id, t);
      const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename ?? id);
      document.body.appendChild(fileLink);

      fileLink.click();
    } finally {
    }
  }

  async downloadTempFile(id: string, filename?: string) {
    return this.downloadFile(id, filename);
  }
}

const apiFile = new ApiFile();

export default apiFile;
