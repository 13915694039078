export default class Person {
  constructor() {
    this.personType = "Natural";
    this.sex = "M";
  }

  id?: string;
  name1?: string;
  name2?: string;
  personType: string | undefined;
  sex: string | undefined;
  title: string | undefined;
  personName: string | undefined;
  ihkNumber: string | undefined;
  clusterTypes: string[] = [];

  public isLossAdjuster(): boolean {
    //return true;
    return this.clusterTypes.some((t) => t.toLowerCase() === "lossadjuster");
  }

  public isCustomer(): boolean {
    //return true;
    return this.clusterTypes.some((t) => t.toLowerCase() === "customer");
  }

  public isBranch(): boolean {
    //return true;
    return this.clusterTypes.some((t) => t.toLowerCase() === "branch");
  }

  public isManufacturer(): boolean {
    //return true;
    return this.clusterTypes.some((t) => t.toLowerCase() === "manufacturer");
  }

  public isSupplier(): boolean {
    //return true;
    return this.clusterTypes.some((t) => t.toLowerCase() === "supplier");
  }

  public isEmployee(): boolean {
    //return true;
    return this.clusterTypes.some((t) => t.toLowerCase() === "employee");
  }

  public getFullPersonName(): string {
    //return true;
    return this.personName ?? (this.personType === "Legal" ? this.name1 + " " + this.name2 : this.name2 + " " + this.name1);
  }

}
