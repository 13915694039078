import moment from "moment/moment";
import {getDateTimeString} from "@/shared/utils/DateUtils";

export default class ConfigItem {
    key? :string
    value? :string

    public getAsDateTime():Date|undefined{
        if(this.value) {
            const t_moment = moment(this.value,'YYYY-MM-DD hh:mm:ss' )
            return t_moment.toDate();
        }
        return undefined
    }

    public setAsDateTime(value:Date){
        this.value = getDateTimeString(value);
    }

}