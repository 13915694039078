import {RouteRecordRaw} from "vue-router";
import InsuranceExportBlankDetailsPage from './pages/InsuranceExportBlankDetailsPage.vue';
import ListPage from "@/modules/InsuranceExportBlank/pages/ListPage.vue";

const insuranceExportBlankRoutes: Array<RouteRecordRaw> = [{
  path: '/insuranceexportblank',
  children: [
    {
      path: 'details/:id',
      component: InsuranceExportBlankDetailsPage,
      props: true,
    },
    {
      path: 'list',
      component: ListPage,
    }
  ]
}
];

export default insuranceExportBlankRoutes;
