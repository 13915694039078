import {Api} from "@/shared/services/api";
import VehicleRegistration from "@/shared/model/VehicleRegistration";
import axios, {AxiosRequestConfig} from "axios";

export class ApiVehicleRegistration extends Api {
  async load(id: string): Promise<{ vehicleRegistration: VehicleRegistration } | undefined> {
    const request = {
      vehicleRegistrationId: id,
    };
    const resp = await this.post("/VehicleRegistrationLoadRequest", request);
    if (resp.successful && resp.vehicleRegistration) {
      return {vehicleRegistration: resp.vehicleRegistration};
    }
    return undefined;
  }

  public async addTempFile(fileId: string, vehicleRegistrationId?: string, name?: string, filename?: string): Promise<boolean | undefined> {
    const resp = await this.post("/VehicleRegistrationAddTempFileRequest", {
      tempFile: fileId,
      refId: vehicleRegistrationId,
      fileName: filename,
      name: name
    });
    return resp.successful;
  }

  public async update(vehicleRegistration: VehicleRegistration, updateMode: string): Promise<boolean | undefined> {
    const resp = await this.post("/VehicleRegistrationUpdateRequest", {
      vehicleRegistration: vehicleRegistration,
      updateMode: updateMode
    });
    return resp.successful;
  }

  /*{
  "vehicleRegistrationId": "string",
  "insuranceNumber": "string",
  "memo": "string",
  "remove": true
}*/
  public async insuranceLink(vehicleRegistrationId: string, insuranceNumber: string, memo: string | undefined, remove: boolean): Promise<boolean | undefined> {
    const resp = await this.post("/VehicleRegistrationInsuranceLinkRequest", {
      vehicleRegistrationId: vehicleRegistrationId,
      insuranceNumber: insuranceNumber,
      memo: memo,
      remove: remove
    });
    return resp.successful;
  }

  /*
  * API:  VehicleRegistrationIgnoreRequest*
  {
  "id": "string",
  "memo": "string"
}*/
  public async ignore(vehicleRegistrationId: string, memo: string | undefined): Promise<boolean | undefined> {
    const resp = await this.post("/VehicleRegistrationIgnoreRequest", {
      id: vehicleRegistrationId,
      memo: memo
    });
    return resp.successful;
  }


  async downloadFile(id: string) {
    try {
      const url = process.env.BASE_URL + '/gdv/gdvmessage/file/' + id;
      //    process.env.BASE_URL + 'bs/FileUtility/UploadTempFile'

      const t: AxiosRequestConfig = {
        ...this.getConfigApi(),
        url: url,
        method: "GET",
        responseType: "blob",
      };

      const resp = await axios.get('/gdv/gdvmessage/file/' + id + ".pdf", t);
//      const resp = await axios.get('/gdv/gdvmessage/file/' + id + ".pdf", t);
      const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", id + ".pdf");
      document.body.appendChild(fileLink);

      fileLink.click();
    } finally {
    }
  }

  async downloadGdvMessageFile(filename: string) {
    return this.downloadFile(filename);
  }


}


const apiVehicleRegistration = new ApiVehicleRegistration();

export default apiVehicleRegistration;

