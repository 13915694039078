import {defineStore} from "pinia";
import {reactive, ref} from "vue";

export const useInsuranceExportBlankStore = defineStore("insurance_export_blank_store", () => {
  const template = ref<string | undefined>(undefined);
  const listPage = reactive({ number : "", status : "all",printType : "all"})
  function setTemplate(value: string) {
    template.value = value;
  }

  return {setTemplate, template, listPage};
});
