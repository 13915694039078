import { RouteRecordRaw } from "vue-router";
import DeliveryListPage from "@/modules/Store/pages/DeliveryListPage.vue";
import ListPage from "@/modules/Store/pages/StoreListPage.vue";

const storeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/store/delivery",
    component: DeliveryListPage
  },
  {
    path:"/store/list/:type?",
    component:ListPage
  }
];

export default storeRoutes;
