export function fillNumberWithZeros(number: number, zerosCount: number): string {
  const numberString = number.toString();

  if (numberString.length >= zerosCount) {
    return numberString;
  }

  const zerosToAdd = zerosCount - numberString.length;
  return '0'.repeat(zerosToAdd) + numberString;
}
