import {useAccountStore} from "@/stores/accountStore";

export function orderTypeDescription(typeId?: string) {
  switch (typeId) {
    case "Order":
      return "Bestellung";
    case "DeliveryNote":
      return "Lieferschein";
    case "Invoice":
      return "Rechnung";
    case "AccumulativeInvoice":
      return "Sammelrechnung";
    default:
      return "keine";
  }
}

export function getOrderTypes() {

  const _accountStore = useAccountStore();
  
  

  return _accountStore.hasAccountingLicense()?[
    /*
    { id: "Order", value: "Bestellung" },
    { id: "DeliveryNote", value: "Lieferschein" },
*/
    { id: "Invoice", value: "Rechnung" },
    { id: "AccumulativeInvoice", value: "Sammelrechnung" },
    { id: "", value: "keine" },
  ]:
    [    { id: "", value: "keine" },
    ];
}
