<template>
  <div class="e-card-header">
    <div class="e-card-header-caption">
      <div class="e-card-header-title">
        <slot />
      </div>
      <div class="e-card-sub-title">
        <slot name="SubTitle" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>

</style>
