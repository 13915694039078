import moment from 'moment/moment'

export function getDateTimeString (date: any) {
  if (date) { return moment(date).format('YYYY-MM-DD HH:mm:ss') }
  return ''
}

export function getDateString (date: any) {
  if (date) { return moment(date).format('YYYY-MM-DD') }
  return ''
}

export function addMonths (date:any, months:number):Date {
  const endDateMoment = moment(date) // moment(...) can also be used to parse dates in string format
  return endDateMoment.add(months, 'months').toDate()
}

export function addDays (date:any, days:number):Date {
  const endDateMoment = moment(date) // moment(...) can also be used to parse dates in string format
  return endDateMoment.add(days, 'days').toDate()
}


export function lastDayOfMonths (date:any):Date {
  const y = date.getFullYear()
  const m = date.getMonth()
  return new Date(y, m + 1, 0)
}

export function firstDayOfMonths (date:any):Date {
  const y = date.getFullYear()
  const m = date.getMonth()
  return new Date(y, m , 1)
}
