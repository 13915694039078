import {Api} from "@/shared/services/api";
import History from "@/shared/model/History";
import HistoryDetails from "@/shared/model/HistoryDetails";
import copyTo from "@/shared/utils/CopyTo";
import {HistoryDetailsType} from "@/shared/model/HistoryDetailsType";
import HistoryDetailsString from "@/shared/model/HistoryDetailsString";
import HistoryDetailsOrderHeadInfo from "@/shared/model/HistoryDetailsOrderHeadInfo";
import HistoryDetailsOrderItemToInsuranceInfo from "@/shared/model/HistoryDetailsOrderItemToInsuranceInfo";
import HistoryDetailsIdString from "@/shared/model/HistoryDetailsIdString";

export class ApiHistory extends Api {

  private async list(id: string, type: string): Promise<History[] | undefined> {
    const request = {
      refId: id,
      refType: type
    };
    const resp = await this.post("/HistoryListRequest", request);
    if (resp.successful && resp.items) {
      return resp.items;
    }
    return undefined;
  }

  async listVehicleRegistration(id: string): Promise<History[] | undefined> {
    return await this.list(id, "VehicleRegistration")
  }

  async listOrder(id: string): Promise<History[] | undefined> {
    return await this.list(id, "Order")
  }

  async listInsurance(id: string): Promise<History[] | undefined> {
    return await this.list(id, "Insurance")
  }

  async listInsuranceExportBlank(id: string): Promise<History[] | undefined> {
    return await this.list(id, "InsuranceExportBlank")
  }

    async listInsuranceCancelation(id: string): Promise<History[] | undefined> {
      return await this.list(id, "InsuranceCancelation")
    }

  async details(id: string): Promise<HistoryDetails | undefined> {
    const request = {
      id: id,
    };
    const resp = await this.post("/HistoryDetailsRequest", request);
    if (resp.successful && resp.details?.detailsObject) {
      let res = undefined;
      switch (resp.details.detailsObject.$type) {
        case HistoryDetailsType.OrderHeadInfo:
          res = new HistoryDetailsOrderHeadInfo();
          break
        case HistoryDetailsType.OrderItemToInsuranceInfo:
          res = new HistoryDetailsOrderItemToInsuranceInfo();
          break
        case HistoryDetailsType.String:
          res = new HistoryDetailsString();
          break
        case HistoryDetailsType.IdString:
          res = new HistoryDetailsIdString();
          break
      }
      if (res) {
        copyTo(resp.details.detailsObject, res)
        return res;
      }
    }
    return undefined;
  }

}

const apiHistory = new ApiHistory();

export default apiHistory;

