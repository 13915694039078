import {RouteRecordRaw} from "vue-router";
import PeriodListPage from "@/modules/WakamBordereau/pages/PeriodListPage.vue";

const bordereauRoutes: Array<RouteRecordRaw> = [
    {
        path: "/bordereau/:id",
        component:PeriodListPage

    }
];

export default bordereauRoutes;