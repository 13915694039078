import {defineStore} from "pinia";
import {reactive} from "vue";
import Insurance from "@/shared/model/Insurance";

export const useInsuranceStore = defineStore("insurance", () => {

  const listPage = reactive({number: "", hasVehicle: "all", activated: "all"})
  const printExportInsurancePage = reactive({insurances: [] as Insurance[]})
  const evbActivationPage = reactive({ids: [] as string[]})

  return {
    listPage, printExportInsurancePage, evbActivationPage
  };
});
