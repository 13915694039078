/* eslint-disable no-console */

import {register} from "register-service-worker";

//if (process.env.NODE_ENV === "production")
{
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "app is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("New content is available; please refresh.");
      const confirmationResult = confirm(
        "New content found! Do you want to reload the app?"
      );
      if (confirmationResult)
        registration?.waiting?.postMessage({action: "skipWaiting"});
    },
    offline() {
      console.log(
        "No internet connection found. app is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  })

  let refreshing = false;
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    console.log("controllerchange");
    if (refreshing) return;
    console.log("reload");
    window.location.reload();
    refreshing = true;
  });

  navigator.serviceWorker.addEventListener("fetch", () => {
    return false;
  });

}
