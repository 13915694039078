export interface Country {
  countryCode: string;
  countryName: string;
}

export const countries: Country[] = [
  { countryCode: "DE", countryName: "Deutschland" },
  { countryCode: "EG", countryName: "Ägypten" },
  { countryCode: "AL", countryName: "Albanien" },
  { countryCode: "DZ", countryName: "Algerien" },
  { countryCode: "AS", countryName: "Amerikanisch-Samoa" },
  { countryCode: "AD", countryName: "Andorra" },
  { countryCode: "AO", countryName: "Angola" },
  { countryCode: "AI", countryName: "Anguilla" },
  { countryCode: "AQ", countryName: "Antarktis" },
  { countryCode: "AG", countryName: "Antigua und Barbuda" },
  { countryCode: "GQ", countryName: "Äquatorialguinea" },
  { countryCode: "AR", countryName: "Argentinien" },
  { countryCode: "AM", countryName: "Armenien" },
  { countryCode: "AW", countryName: "Aruba" },
  { countryCode: "AZ", countryName: "Aserbaidschan" },
  { countryCode: "ET", countryName: "Äthiopien" },
  { countryCode: "AU", countryName: "Australien" },
  { countryCode: "BS", countryName: "Bahamas" },
  { countryCode: "BH", countryName: "Bahrain" },
  { countryCode: "BD", countryName: "Bangladesch" },
  { countryCode: "BB", countryName: "Barbados" },
  { countryCode: "BE", countryName: "Belgien" },
  { countryCode: "BZ", countryName: "Belize" },
  { countryCode: "BJ", countryName: "Benin" },
  { countryCode: "BM", countryName: "Bermuda" },
  { countryCode: "BT", countryName: "Bhutan" },
  { countryCode: "BO", countryName: "Bolivien" },
  { countryCode: "BQ", countryName: "Bonaire, Saint Eustatius und Saba" },
  { countryCode: "BA", countryName: "Bosnien und Herzegowina" },
  { countryCode: "BW", countryName: "Botswana" },
  { countryCode: "BV", countryName: "Bouvetinsel" },
  { countryCode: "BR", countryName: "Brasilien" },
  {
    countryCode: "IO",
    countryName: "Britisches Territorium im Indischen Ozean",
  },
  { countryCode: "BN", countryName: "Brunei Darussalam" },
  { countryCode: "BG", countryName: "Bulgarien" },
  { countryCode: "BF", countryName: "Burkina Faso" },
  { countryCode: "BI", countryName: "Burundi" },
  { countryCode: "CL", countryName: "Chile" },
  { countryCode: "CN", countryName: "China" },
  { countryCode: "CK", countryName: "Cook-Inseln" },
  { countryCode: "CR", countryName: "Costa Rica" },
  { countryCode: "CW", countryName: "Curaçao" },
  { countryCode: "DK", countryName: "Dänemark" },
  { countryCode: "DE", countryName: "Deutschland" },
  { countryCode: "DM", countryName: "Dominica" },
  { countryCode: "DO", countryName: "Dominikanische Republik" },
  { countryCode: "DJ", countryName: "Dschibuti" },
  { countryCode: "EC", countryName: "Ecuador" },
  { countryCode: "SV", countryName: "El Salvador" },
  { countryCode: "CI", countryName: "Elfenbeinküste" },
  { countryCode: "ER", countryName: "Eritrea" },
  { countryCode: "EE", countryName: "Estland" },
  { countryCode: "FK", countryName: "Falkland-Inseln (Malwinen)" },
  { countryCode: "FO", countryName: "Färöer-Inseln" },
  { countryCode: "FJ", countryName: "Fidschi" },
  { countryCode: "FI", countryName: "Finnland" },
  { countryCode: "FR", countryName: "Frankreich" },
  { countryCode: "GF", countryName: "Französisch-Guayana" },
  { countryCode: "PF", countryName: "Französisch-Polynesien" },
  { countryCode: "TF", countryName: "Französische Südgebiete" },
  { countryCode: "GA", countryName: "Gabun" },
  { countryCode: "GM", countryName: "Gambia" },
  { countryCode: "GE", countryName: "Georgien" },
  { countryCode: "GH", countryName: "Ghana" },
  { countryCode: "GI", countryName: "Gibraltar" },
  { countryCode: "GD", countryName: "Grenada" },
  { countryCode: "GR", countryName: "Griechenland" },
  { countryCode: "GL", countryName: "Grönland" },
  { countryCode: "GP", countryName: "Guadeloupe" },
  { countryCode: "GU", countryName: "Guam" },
  { countryCode: "GT", countryName: "Guatemala" },
  { countryCode: "GN", countryName: "Guinea" },
  { countryCode: "GW", countryName: "Guinea-Bissau" },
  { countryCode: "GY", countryName: "Guyana" },
  { countryCode: "HT", countryName: "Haiti" },
  { countryCode: "HM", countryName: "Heard- und McDonald-Inseln" },
  { countryCode: "HN", countryName: "Honduras" },
  { countryCode: "HK", countryName: "Hongkong" },
  { countryCode: "IN", countryName: "Indien" },
  { countryCode: "ID", countryName: "Indonesien" },
  { countryCode: "IE", countryName: "Irland" },
  { countryCode: "IS", countryName: "Island" },
  { countryCode: "IL", countryName: "Israel" },
  { countryCode: "IT", countryName: "Italien" },
  { countryCode: "JM", countryName: "Jamaika" },
  { countryCode: "JP", countryName: "Japan" },
  { countryCode: "YE", countryName: "Jemen" },
  { countryCode: "JO", countryName: "Jordanien" },
  { countryCode: "VG", countryName: "Jungferninseln, Britisch" },
  { countryCode: "VI", countryName: "Jungferninseln, USA" },
  { countryCode: "KY", countryName: "Kaiman-Inseln" },
  { countryCode: "KH", countryName: "Kambodia" },
  { countryCode: "CM", countryName: "Kamerun" },
  { countryCode: "CA", countryName: "Kanada" },
  { countryCode: "CV", countryName: "Kap Verde" },
  { countryCode: "KZ", countryName: "Kasachstan" },
  { countryCode: "KE", countryName: "Kenia" },
  { countryCode: "KG", countryName: "Kirgisien" },
  { countryCode: "KI", countryName: "Kiribati" },
  { countryCode: "UM", countryName: "Kleinere Inselterritorien der USA" },
  { countryCode: "CC", countryName: "Kokosinseln (Keeling-Inseln)" },
  { countryCode: "CO", countryName: "Kolumbien" },
  { countryCode: "KM", countryName: "Komoren" },
  { countryCode: "CG", countryName: "Kongo" },
  { countryCode: "CD", countryName: "Kongo, Demokratische Republik" },
  { countryCode: "KR", countryName: "Korea, Republik" },
  { countryCode: "XK", countryName: "Kosovo" },
  { countryCode: "HR", countryName: "Kroatien" },
  { countryCode: "KW", countryName: "Kuwait" },
  { countryCode: "LA", countryName: "Lao, Demokratische Volksrepublik" },
  { countryCode: "LS", countryName: "Lesotho" },
  { countryCode: "LV", countryName: "Lettland" },
  { countryCode: "LB", countryName: "Libanon" },
  { countryCode: "LR", countryName: "Liberia" },
  { countryCode: "LY", countryName: "Libyen" },
  { countryCode: "LI", countryName: "Liechtenstein" },
  { countryCode: "LT", countryName: "Litauen" },
  { countryCode: "LU", countryName: "Luxemburg" },
  { countryCode: "MO", countryName: "Macao" },
  { countryCode: "MG", countryName: "Madagaskar" },
  { countryCode: "MW", countryName: "Malawi" },
  { countryCode: "MY", countryName: "Malaysia" },
  { countryCode: "MV", countryName: "Malediven" },
  { countryCode: "ML", countryName: "Mali" },
  { countryCode: "MT", countryName: "Malta" },
  { countryCode: "MA", countryName: "Marokko" },
  { countryCode: "MH", countryName: "Marshallinseln" },
  { countryCode: "PM", countryName: "Marshallinseln" },
  { countryCode: "MQ", countryName: "Martinique" },
  { countryCode: "MR", countryName: "Mauritanien" },
  { countryCode: "MU", countryName: "Mauritius" },
  { countryCode: "YT", countryName: "Mayotte" },
  {
    countryCode: "MK",
    countryName: "Mazedonien, ehemalige jugoslawische Republik",
  },
  { countryCode: "MX", countryName: "Mexiko" },
  { countryCode: "FM", countryName: "Mikronesien, Föderierte Staaten von" },
  { countryCode: "MD", countryName: "Moldawien, Republik" },
  { countryCode: "MC", countryName: "Monaco" },
  { countryCode: "MN", countryName: "Mongolei" },
  { countryCode: "ME", countryName: "Montenegro" },
  { countryCode: "MS", countryName: "Montserrat" },
  { countryCode: "MZ", countryName: "Mosambik" },
  { countryCode: "MM", countryName: "Myanmar" },
  { countryCode: "NA", countryName: "Namibia" },
  { countryCode: "NR", countryName: "Nauru" },
  { countryCode: "NP", countryName: "Nepal" },
  { countryCode: "NC", countryName: "Neukaledonien" },
  { countryCode: "NZ", countryName: "Neuseeland" },
  { countryCode: "NI", countryName: "Nicaragua" },
  { countryCode: "NL", countryName: "Niederlande" },
  { countryCode: "AN", countryName: "Niederländische Antillen" },
  { countryCode: "NE", countryName: "Niger" },
  { countryCode: "NG", countryName: "Nigeria" },
  { countryCode: "NU", countryName: "Niue" },
  { countryCode: "MP", countryName: "Nördliche Marianen" },
  { countryCode: "NF", countryName: "Norfolkinseln" },
  { countryCode: "NO", countryName: "Norwegen" },
  { countryCode: "OM", countryName: "Oman" },
  { countryCode: "AT", countryName: "Österreich" },
  { countryCode: "TL", countryName: "Osttimor" },
  { countryCode: "PK", countryName: "Pakistan" },
  { countryCode: "PW", countryName: "Palau" },
  { countryCode: "PA", countryName: "Panama" },
  { countryCode: "PG", countryName: "Papua-Neuguinea" },
  { countryCode: "PY", countryName: "Paraguay" },
  { countryCode: "PE", countryName: "Peru" },
  { countryCode: "PH", countryName: "Philippinen" },
  { countryCode: "PN", countryName: "Pitcairn" },
  { countryCode: "PL", countryName: "Polen" },
  { countryCode: "PT", countryName: "Portugal" },
  { countryCode: "PR", countryName: "Puerto Rico" },
  { countryCode: "QA", countryName: "Qatar" },
  { countryCode: "RE", countryName: "Reunion" },
  { countryCode: "RW", countryName: "Ruanda" },
  { countryCode: "RO", countryName: "Rumänien" },
  { countryCode: "RU", countryName: "Russische Föderation" },
  {
    countryCode: "SH",
    countryName: "Saint Helena, Ascension und Tristan da Cunha",
  },
  { countryCode: "KN", countryName: "Saint Kitts und Nevis" },
  { countryCode: "LC", countryName: "Saint Lucia" },
  { countryCode: "VC", countryName: "Saint Vincent und Grenadinen" },
  { countryCode: "ZM", countryName: "Sambia" },
  { countryCode: "WS", countryName: "Samoa" },
  { countryCode: "SM", countryName: "San Marino" },
  { countryCode: "ST", countryName: "Sao Tome und Principe" },
  { countryCode: "SA", countryName: "Saudi Arabien" },
  { countryCode: "SE", countryName: "Schweden" },
  { countryCode: "CH", countryName: "Schweiz" },
  { countryCode: "SN", countryName: "Senegal" },
  { countryCode: "RS", countryName: "Serbien" },
  { countryCode: "SC", countryName: "Seychellen" },
  { countryCode: "SL", countryName: "Sierra Leone" },
  { countryCode: "ZW", countryName: "Simbabwe" },
  { countryCode: "SG", countryName: "Singapur" },
  { countryCode: "SX", countryName: "Sint Maarten" },
  { countryCode: "SK", countryName: "Slowakei" },
  { countryCode: "SI", countryName: "Slowenien" },
  { countryCode: "SB", countryName: "Solomon-Inseln" },
  { countryCode: "SO", countryName: "Somalia" },
  { countryCode: "ES", countryName: "Spanien" },
  { countryCode: "LK", countryName: "Sri Lanka" },
  { countryCode: "ZA", countryName: "Südafrika" },
  {
    countryCode: "GS",
    countryName: "Südgeorgien und die südlichen Sandwich-Inseln",
  },
  { countryCode: "SR", countryName: "Suriname" },
  { countryCode: "SJ", countryName: "Svalbard- und Jan Mayen-Inseln" },
  { countryCode: "SZ", countryName: "Swasiland" },
  { countryCode: "TJ", countryName: "Tadschikistan" },
  { countryCode: "TW", countryName: "Taiwan" },
  { countryCode: "TZ", countryName: "Tansania, Vereinte Republik" },
  { countryCode: "TH", countryName: "Thailand" },
  { countryCode: "TG", countryName: "Togo" },
  { countryCode: "TK", countryName: "Tokelau" },
  { countryCode: "TO", countryName: "Tonga" },
  { countryCode: "TT", countryName: "Trinidad und Tobago" },
  { countryCode: "TD", countryName: "Tschad" },
  { countryCode: "CZ", countryName: "Tschechische Republik" },
  { countryCode: "TN", countryName: "Tunesien" },
  { countryCode: "TR", countryName: "Türkei" },
  { countryCode: "TM", countryName: "Turkmenistan" },
  { countryCode: "TC", countryName: "Turks- und Caicos-Inseln" },
  { countryCode: "TV", countryName: "Tuvalu" },
  { countryCode: "UG", countryName: "Uganda" },
  { countryCode: "UA", countryName: "Ukraine" },
  { countryCode: "HU", countryName: "Ungarn" },
  { countryCode: "UY", countryName: "Uruguay" },
  { countryCode: "UZ", countryName: "Usbekistan" },
  { countryCode: "VU", countryName: "Vanuatu" },
  { countryCode: "VA", countryName: "Vatikanstadt" },
  { countryCode: "VE", countryName: "Venezuela" },
  { countryCode: "AE", countryName: "Vereinigte Arabische Emirate" },
  { countryCode: "US", countryName: "Vereinigte Staaten von Amerika" },
  { countryCode: "GB", countryName: "Vereinigtes Königreich" },
  { countryCode: "VN", countryName: "Vietnam" },
  { countryCode: "WF", countryName: "Wallis und Futuna" },
  { countryCode: "CX", countryName: "Weihnachtsinsel" },
  { countryCode: "BY", countryName: "Weißrussland" },
  { countryCode: "EH", countryName: "Westsahara" },
  { countryCode: "CF", countryName: "Zentralafrikanische Republik" },
  { countryCode: "CY", countryName: "Zypern" },
];
