<template>
  <div class="e-appbar-spacer" />
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>
