import {defineStore} from "pinia";
import {computed, reactive, ref, watch} from "vue";
import {ShoppingCart} from "@/shared/model/shoppingCart";
import ShoppingCartItem from "@/shared/model/shoppingCartItem";
import {useAccountStore} from "@/stores/accountStore";
import {ProductType} from "@/shared/model/productType";
import Product from "@/shared/model/product";
import ProductPrice from "@/shared/model/productPrice";
import copyTo from "@/shared/utils/CopyTo";
import apiOrganisationStructure from "@/modules/OrganisationStructure/api";
import getGuid from "@/shared/utils/getGuid";
import InsuranceExportBlank, {sortInsuranceExportBlank} from "@/shared/model/InsuranceExportBlank";
import PersonDetailsId from "@/shared/model/personDetailsId";
import Address from "@/shared/model/Address";
import Person from "@/shared/model/person";
import Insurance, {sortInsuranceByNumber} from "@/shared/model/Insurance";
import {useRouter} from "vue-router";

export const useShoppingCartStore = defineStore("shoppingCart", () => {
  const currentShoppingCartId = ref("");

  const shoppingCarts: ShoppingCart[] = reactive([]);
  const _router = useRouter()
  const _single = ref(false)


  function getShoppingCart(id: string): ShoppingCart {
    let t_cart = shoppingCarts.find((t) => t.id === id);
    if (!t_cart) {
      t_cart = new ShoppingCart();
      t_cart.personId = id;
      const config = useAccountStore();
      const t_isSale = config.person?.id !== id
      t_cart.isSale = t_isSale
      t_cart.supplierId = t_isSale ? config.person?.id : "";
      shoppingCarts.push(t_cart);
    }
    return t_cart;
  }

  async function loadProductPrices(cart: ShoppingCart) {
    const key = getGuid()
    const result = await apiOrganisationStructure.loadProductPrice(
      key,
      cart.personId,
      true,
      cart.isSale ? "sale" : "purchase"
    );
    if (result && result.key === key) {
      cart.productPrices = result?.prices;
      cart.products = result?.products;
    }
  }

  async function initShoppingCart(personId: string) {
    const t_cart = await getShoppingCart(personId);
    await loadProductPrices(t_cart);
    currentShoppingCartId.value = t_cart.personId;
    currentProductTypes.kzk = hasProductType(ProductType.Kzk);
    currentProductTypes.zoll = hasProductType(ProductType.Zoll);
    currentProductTypes.tz = hasProductType(ProductType.Tz);
    currentProductTypes.exportBlank = hasProductType(ProductType.ExportBlank);
  }

  function initSale() {
    if (currentShoppingCartId.value) {
      const shoppingCart = getCurrentShoppingCart();
      if (!shoppingCart.isSale) {
        currentShoppingCartId.value = ""
      }
    }
  }

  async function initPurchase() {
    const config = useAccountStore();
    if (config.person?.id) {
      await initShoppingCart(config.person?.id);
      const t_cart = getShoppingCart(currentShoppingCartId.value);
      t_cart.supplierId = ""

      t_cart.invoiceType = config.details?.getValue(PersonDetailsId.OrderTypeId)

      if (config.person) {
        copyTo(config.person, t_cart.invoicePerson.Person);
      }

      const address = config.getInvoiceAddress();
      if (address && !address.fullName) {
        address.fullName = config.person?.personName;
      }
      copyTo(address, t_cart.invoicePerson.Address);
    }
  }

  const currentProductTypes = reactive({kzk: false, zoll: false, tz: false, exportBlank: false})


  function hasProductType(value: ProductType): boolean {
    const t_cart = getShoppingCart(currentShoppingCartId.value);
    return t_cart.productPrices.some((t) => t.productTypeId === value);
  }

  async function addItem() {
    const t_cart = getShoppingCart(currentShoppingCartId.value);
    const value = getCurrentItem().shoppingCartItem
    const t_item = t_cart.items.some((t) => t.id == value.id);
    if (t_item) {
      copyTo(t_item, value);
    } else {
      t_cart.items.push(value);
    }
    t_cart.clearCurrentItem();
    if (_single.value) {
      if (value.insuranceType === "Kzk") {
        await navigate("evb")
      } else {
        await navigate("cashpoint")
      }

    } else {
      await _router.push("list");
    }
  }

  function removeItem(id: string) {
    const t_cart = getShoppingCart(currentShoppingCartId.value);
    const t_item = t_cart.items.some((t) => t.id == id);
    if (t_item) {
      const items: ShoppingCartItem[] = [];
      items.push(...t_cart.items);
      t_cart.items.length = 0;
      t_cart.items.push(...items.filter((t) => t.id !== id));
    }
  }

  function clear() {
    const t_cart = getShoppingCart(currentShoppingCartId.value);
    t_cart.activation = false;
    t_cart.activationPerson = {Person: new Person(), Address: new Address()}
    t_cart.items.length = 0;
    t_cart.clearCurrentItem();
    if (t_cart.isSale) {
      currentShoppingCartId.value = "";
    }
  }

  function getItems(): ShoppingCartItem[] {
    const t_cart = getShoppingCart(currentShoppingCartId.value);
    return t_cart.items;
  }

  function amount(): number {
    const t_cart = getShoppingCart(currentShoppingCartId.value);
    let t_amount = 0;
    t_cart.items.map((t) => {
      t_amount += t.amount;
    });

    return t_amount;
  }

  watch(shoppingCarts, () => {
    count.value = getShoppingCart(currentShoppingCartId.value).items.length;
  });

  const count = ref(0);

  function getCurrentShoppingCart(): ShoppingCart {
    return getShoppingCart(currentShoppingCartId.value);
  }

  const currentShoppingCart = computed(() => {
    return getCurrentShoppingCart();
  })

  function getCurrentItem() {
    return getCurrentShoppingCart().currentItem;
  }

  const currentItem = computed(() => {
    return getCurrentItem();
  })

  const currentBaseProducts = computed(() => {
    return currentItem.value.baseProducts;
  })


  async function setProductType(value: ProductType) {
    const t_cart = getCurrentShoppingCart();
    const t_currentItem = getCurrentItem()
    t_currentItem.baseProductType = value;
    t_currentItem.baseProducts.length = 0;
    const t_products = t_cart.products.filter((t) => t.productTypeId === value);
    const t_baseProducts: Product[] = []
    t_products.forEach(t => {
      if (t_currentItem.baseProducts.every(b => b.productKeyWithoutManufacturer !== t.productKeyWithoutManufacturer)) {
        const p = new Product()
        copyTo(t, p)
        p.manufacturerId = undefined;
        p.saleTypes.length = 0;
        t_products.filter(c => c.productKeyWithoutManufacturer === t.productKeyWithoutManufacturer).map(v => p.saleTypes.push(...v.saleTypes))
        t_baseProducts.push(p)
      }
    })
    t_currentItem.baseProducts = t_baseProducts;
    if (value === ProductType.ExportBlank && t_currentItem.baseProducts.length > 0) {
      await setBaseProduct(t_currentItem.baseProducts[0])
    }
  }

  async function setBaseProduct(value: Product) {
    const t_cart = getCurrentShoppingCart();
    const t_currentItem = getCurrentItem()
    const t_productPrices = t_cart.productPrices.filter((t) => t.productKeyWithoutManufacturer === value.productKeyWithoutManufacturer);


    const t_array: ProductPrice[] = []

    t_productPrices.map(t => {
      t.saleTypes.map(type => {
        const t_price = new ProductPrice();
        copyTo(t, t_price);
        t_price.saleTypes = [type];
        t_array.push(t_price)
      })
    })


    /*
        if(value.isStoreExportBlank) {
          t_currentItem.productPrices.length = 0;
          t_productPrices.map(t => {
              t_currentItem.productPrices.push(t)
              if (!t.isJustInTime) {
                t.saleTypes.push("StoreExportBlank")
              } else {
                const n = new ProductPrice();
                copyTo(t, n);
                n.saleTypes.push("StoreExportBlank")
                n.saleTypes = n.saleTypes.filter(t => t != "JustInTime")
                t_currentItem.productPrices.push(n)
              }
            }
          )
        }
        else{
          t_currentItem.productPrices = t_productPrices;
        }
    */
    t_currentItem.productPrices = t_array.sort((a, b) => {

      let r: number | undefined = undefined;
      if (a.saleTypes.length === 1 && b.saleTypes.length === 1) {
        const sa = a.isJustInTime ? 0 : a.isStore ? 1 : a.isStoreExportBlank ? 2 : 3
        const sb = b.isJustInTime ? 0 : b.isStore ? 1 : b.isStoreExportBlank ? 2 : 3
        r = sa - sb
      }
      if (r) {
        return r
      }
      r = a.manufacturerName?.localeCompare(b.manufacturerName ?? "")
      if (r) {
        return r
      }
      return a.grossPrice - b.grossPrice
    });


    if (t_currentItem.productPrices.length === 1) {
      await setProductPrice(t_currentItem.productPrices[0])
    } else {
      await navigate("productprice")
    }
  }

  async function navigate(page: string) {
    if (_single.value) {
      await _router.push({path: page})
    } else {
      await _router.push({path: "add", query: {page: page}})
    }
  }

  async function setProductPrice(value?: ProductPrice) {
    const t_currentItem = getCurrentItem()
    t_currentItem.shoppingCartItem.productPrice = value;

    if (value?.isJustInTime) {
      await navigate("premium")
      return
    }
    if (value?.isStoreExportBlank) {
      await navigate("storeexportblank")
      return
    }
    if (value?.isInsurance && value.isStore) {
      getCurrentItem().shoppingCartItem.insurances.length = 0
      await navigate("store")
      return
    }
    if (value?.productTypeId === "ExportBlank") {
      if (useAccountStore().isRoot) {
        await navigate("exportblank")
      } else {
        await navigate("storeexportblank")
      }
      return
    }

  }

  /*
    watch(currentItem, () => {
      console.log("currentItem")
    })
  */

  function addInsuranceExportBlank(blank: InsuranceExportBlank) {
    const t_currentItem = getCurrentItem()
    t_currentItem.shoppingCartItem.insuranceExportBlanks = sortInsuranceExportBlank([...t_currentItem.shoppingCartItem.insuranceExportBlanks, blank])
  }

  function removeInsuranceExportBlank(blank: InsuranceExportBlank) {
    const t_currentItem = getCurrentItem()
    t_currentItem.shoppingCartItem.insuranceExportBlanks = t_currentItem.shoppingCartItem.insuranceExportBlanks.filter(t => t.id != blank.id)
  }

  function addInsurance(insurance: Insurance) {
    const t_currentItem = getCurrentItem()
    t_currentItem.shoppingCartItem.insurances = sortInsuranceByNumber([...t_currentItem.shoppingCartItem.insurances, insurance])
  }

  function removeInsurance(insurance: Insurance) {
    const t_currentItem = getCurrentItem()
    t_currentItem.shoppingCartItem.insurances = t_currentItem.shoppingCartItem.insurances.filter(t => t.id != insurance.id)
  }

  function setInvoiceCircle(value?: string) {
    const c = getCurrentShoppingCart();
    c.invoiceCircle = value;
  }

  async function setSalePerson(value: { Person: Person; Address: Address }) {
    if (value.Person.id) {
      await initShoppingCart(value.Person.id)
      const t_cart = getShoppingCart(currentShoppingCartId.value);
      const resp = await apiOrganisationStructure.loadPerson(t_cart.personId)

      t_cart.invoiceType = resp?.details?.getValue(PersonDetailsId.OrderTypeId)

      if (value.Person) {
        copyTo(value.Person, t_cart.invoicePerson.Person);
      }

      const address = value.Address;
      copyTo(address, t_cart.invoicePerson.Address);

    }
  }

  function setSingle(single: boolean) {
    _single.value = single;
  }

  async function addStoreExportBlanks() {
    currentItem.value.shoppingCartItem.count = currentItem.value.shoppingCartItem.insuranceExportBlanks.length;
    currentItem.value.shoppingCartItem.digitCount = undefined;
    currentItem.value.shoppingCartItem.prefix = undefined;
    currentItem.value.shoppingCartItem.startNumber = undefined;
    currentItem.value.shoppingCartItem.grossPrice = 0;
    if (currentItem.value.shoppingCartItem.productPrice?.productTypeId === "ExportBlank") {
      await addItem();
      return;
    } else {
      await navigate("premium")
      return;
    }
  }


  return {
    addInsuranceExportBlank,
    removeInsuranceExportBlank,
    addInsurance,
    removeInsurance,
    initPurchase,
    initSale,
    hasProductType,
    addItem,
    getItems,
    count,
    amount,
    shoppingCarts,
    currentShoppingCart,
    currentShoppingCartId,
    getCurrentItem,
    removeItem,
    clear,
    setProductType,
    setBaseProduct,
    setProductPrice,
    setInvoiceCircle,
    setSalePerson,
    currentProductTypes,
    currentItem,
    currentBaseProducts,
    setSingle,
    addStoreExportBlanks,
    navigate
  };
});
