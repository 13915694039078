export default class InsuranceExportBlank {
  id?: string
  blankNumber?: string
  manufacturerId?: string
  manufacturerName?: string
  insuranceNumber?: string
  insuranceId?: string
  printType?: PrintType
  printTypeDescription?: string
  printYearDescription?: string
  vehicleRegistrationId?: string
  status?: InsuranceExportBlankStatus
  statusDescription?: string
  kennzeichen?: string
}

export function sortInsuranceExportBlank(array: InsuranceExportBlank[]): InsuranceExportBlank[] {
  return array.sort((a, b) => {
    const r = a.insuranceNumber?.localeCompare(b.insuranceNumber ?? "")
    if (r) {
      return r
    }
    return a.blankNumber?.localeCompare(b.blankNumber ?? "") ?? 0
  });
}

export enum PrintType {
  PrePrinted = "PrePrinted",
  FullPrinted = "FullPrinted",
}

export enum InsuranceExportBlankStatus {
  IncorrectlyFilled = "IncorrectlyFilled",
  Canceled = "Canceled",
  Archived = "Archived",
  Destroyed = "Destroyed"
}

//IncorrectlyFilled auf deutsch: Falsch ausgefüllt


