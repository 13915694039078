import {defineStore} from "pinia";
import Installation from "@/shared/model/Installation";
import {User} from "@/shared/model/User";
import Person from "@/shared/model/person";
import Address from "@/shared/model/Address";
import {Action, allActions, allRootActions} from "@/shared/model/Action";
import PersonDetails from "@/shared/model/personDetails";
import PersonDetailsId from "@/shared/model/personDetailsId";
import {ref} from "vue";
import {computed} from "vue";


export const useAccountStore = defineStore("account", () => {

  const actionPerson = ref<Person | undefined>(undefined);
  const person = ref<Person | undefined>(undefined);

  const addresses = ref<Address[] | undefined>(undefined);
  const details = ref<PersonDetails | undefined>(undefined);
  const user = ref<User | undefined>(undefined);
  const installation = ref<Installation>({} as Installation);
  const authenticated = ref(false)


  function setInstallation(value: Installation) {
    installation.value = value;
  }

  function hasAction(action: string | Action): boolean {
    return (
      (user.value &&
        user.value.actions.some(
          (t) => t.toLowerCase() === action.toLowerCase()
        )) ??
      false
    );
  }

  function hasAccountingLicense(): boolean {
    return isRoot.value
  }

  function getInvoiceAddressId(): string {
    return details.value?.getValue(PersonDetailsId.InvoiceAddressId) ?? "";
  }

  function getAddress(id: string): Address | undefined {
    if (addresses.value) {
      return addresses.value.find((t) => t.id == id);
    }
    return undefined;
  }

  function getInvoiceAddress(): Address | undefined {
    const id = getInvoiceAddressId();
    if (id) return getAddress(id);
    if (addresses.value && addresses.value.length > 0) return addresses.value[0];
    return undefined;
  }

  const isRoot = computed(() => {
    return (person.value && person.value.id === "person_agent22") ?? false;
  })

  const isBranch = computed(() => {
    return (person.value && person.value.isBranch()) ?? false;
  })

  function getManagedActions(): {
    id: string;
    checked: boolean;
    description: string;
  }[] {
    if (isRoot.value) {
      return [...allRootActions];
    }
    return [...allActions];
  }


  return {
    hasAction,
    setInstallation,
    hasAccountingLicense,
    getInvoiceAddressId,
    getAddress,
    getInvoiceAddress,
    getManagedActions,
    actionPerson, person, addresses, details, user, installation, authenticated,
    isRoot, isBranch
  };
});
