import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import HomeView from "../views/HomeView.vue";
import {useAccountStore} from "@/stores/accountStore";
import orderRoutes from "@/modules/Order/routes";
import shoppingCartRoutes from "@/modules/ShoppingCart/routes";
import ImpressumView from "@/views/ImpressumView.vue";
import DatenschutzView from "@/views/DatenschutzView.vue";
import organisationStructureRoutes from "@/modules/OrganisationStructure/routes";
import insuranceRoutes from "@/modules/Insurance/routes";
import apiAuth from "@/shared/services/apiAuth";
import TestPage from "@/modules/Test/TestPage.vue";
import gdvRoutes from "@/modules/Gdv/routes";
import usNatoRoutes from "@/modules/UsNato/routes";
import storeRoutes from "@/modules/Store/routes";
import bordereauRoutes from "@/modules/WakamBordereau/router";
import vehicleregistrationRoutes from "@/modules/VehicleRegistration/router";
import ConsumerInformationView from "@/views/ConsumerInformationView.vue";
import reportRoutes from "@/modules/Report/routes";
import lossAdjusterRoutes from "@/modules/LossAdjuster/routes";
import vbRechercheRoutes from '@/modules/VbRecherche/routes'
import insuranceExportBlankRoutes from "@/modules/InsuranceExportBlank/routes";
import insuranceCancelationRoutes from "@/modules/InsuranceCancelation/router";
import claimsRoutes from "@/modules/Calims/routes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    //component: TestPage,
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/test",
    name: "test",
    component: TestPage,
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/registration",
    component: HomeView,
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/activate/:code",
    component: HomeView,
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/impressum",
    component: ImpressumView,
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/verbraucherinformationen",
    component: ConsumerInformationView,
    meta: {
      noAuth: true,
    },
  },
  {
    path: "/datenschutz",
    component: DatenschutzView,
    meta: {
      noAuth: true,
    },
  },
  /*
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/!* webpackChunkName: "about" *!/ "../views/AboutView.vue"),
    },
  */
];

routes.push(...orderRoutes);
routes.push(...shoppingCartRoutes);
routes.push(...organisationStructureRoutes);
routes.push(...insuranceRoutes);
routes.push(...gdvRoutes);
routes.push(...usNatoRoutes);
routes.push(...storeRoutes);
routes.push(...bordereauRoutes);
routes.push(...vehicleregistrationRoutes);
routes.push(...reportRoutes);
routes.push(...vbRechercheRoutes);
routes.push(...lossAdjusterRoutes);
routes.push(...insuranceExportBlankRoutes);
routes.push(...insuranceCancelationRoutes);
routes.push(...claimsRoutes);

const t_404 = [
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: HomeView,
    //component: TestPage,
    meta: {
      noAuth: true,
    },
  },]
routes.push(...t_404);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export function checkRoute(path: string | undefined): boolean {
  if (!path) {
    path = router.currentRoute.value.path;
  }

  const config = useAccountStore();
  if (
    path === "/lossadjustment/search" &&
    (!config.person || !config.person.isLossAdjuster())
  ) {
    router.push("/").then();
    return false;
  }
  return true;
}

router.beforeEach(async (to) => {
  if (to.meta.noAuth) return;

  if (apiAuth.isAuthenticated()) {
    if (checkRoute(to.path)) {
      //  console.log("");
    }
    return;
  }

  //{ redirectUri: to.fullPath }
  const basePath = window.location.toString();
  await apiAuth.login({redirectUri: basePath});
  //next();
});

/*
router.afterEach((to, from, failure) => {
  console.log("afterEach")
  console.log(to)
  console.log(from)
  console.log(failure)
  if (failure) {
    console.log(to)
    console.log(from)
    console.log(failure)
  }
})
*/

export default router;
