import {Api} from "@/shared/services/api";
import OrderHead from "@/shared/model/orderHead";
import OrderItem from "@/shared/model/orderItem";
import StoreItem from "@/shared/model/StoreItem";
import ProductPrice from "@/shared/model/productPrice";
import Insurance from "@/shared/model/Insurance";
import InsuranceExportBlank from "@/shared/model/InsuranceExportBlank";

export class ApiStore extends Api {
  async listDelivery(personId?: string, supplierId?: string): Promise<{
    orderHeads: OrderHead[];
    orderItems: OrderItem[]
  } | undefined> {
    const request = {
      personId: personId,
      supplierId: supplierId,
      orderType: "order",
      orderStatus: "Sended",
      details: true
    };

    const resp = await this.post("OrderListRequest", request);
    if (resp.successful && resp.items) {
      return {orderHeads: resp.items, orderItems: resp.orderItems};
    }
    return undefined
  }

  async delivery(id: string): Promise<boolean | undefined> {
    const resp = await this.post("OrderDeliveryRequest", {
      id: id,
    });
    return resp.successful;
  }

  async listStore(): Promise<StoreItem[] | undefined> {
    const request = {};

    const resp = await this.post("StoreListRequest", request);
    if (resp.successful && resp.items) {
      return resp.items;
    }
    return undefined
  }

  async listInsurance(price?: ProductPrice, grossPrice?: number, number?: string): Promise<Insurance[] | undefined> {
    const request = {
      carType: price?.carTypeId,
      productType: price?.productTypeId,
      manufacturerId: price?.manufacturerId,
      territoryType: price?.territoryTypeId,
      validityDay: price?.validityDay,
      grossPrice: grossPrice,
      number: number
    };

    const resp = await this.post("StoreInsuranceListRequest", request);
    if (resp.successful && resp.items) {
      return resp.items;
    }
    return undefined
  }

  async listInsuranceExportBlank(withInsuranceNumber = false, number?: string): Promise<InsuranceExportBlank [] | undefined> {
    const request = {
      withInsuranceNumber: withInsuranceNumber,
      number: number
    };

    const resp = await this.post("StoreInsuranceExportBlankRequest", request);
    if (resp.successful && resp.result) {
      return resp.result;
    }
    return undefined
  }

  async countInsuranceExportBlank(withInsuranceNumber: boolean): Promise<number | undefined> {
    const request = {
      countOnly: true,
      withInsuranceNumber: withInsuranceNumber
    };

    const resp = await this.post("StoreInsuranceExportBlankRequest", request);
    if (resp.successful && resp.count) {
      return resp.count;
    }
    return undefined
  }

}

const apiStore = new ApiStore();

export default apiStore;
