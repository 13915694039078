<template>
  <div
    id="main"
    class="content"
    style="height: 500px"
  />
</template>
<script>

import {
  createSpinner,
  showSpinner,
//  hideSpinner, setSpinner
} from "@syncfusion/ej2-vue-popups";

//const dark = true
/*
const a = ref(dark?"main-dark":"main")
*/

//import(`./assets/${dark?"main-dark":"main"}.css`);

export default {
  name: "AppStart",
  mounted: function() {

    const main1 = document.getElementById("main");
    createSpinner({ target: main1, width: '34px', label: 'Loading...' })


/*
    createSpinner({
      // Specify the target for the spinner to show
      target: main1,
    });
*/

    // showSpinner() will make the spinner visible
    showSpinner(main1);
/*
    setInterval(function() {
      // hideSpinner() method used hide spinner
      hideSpinner(main1);
    }, 5000);
*/


  }
}
</script>
<style scoped>
/*
@import "@syncfusion/ej2-base/styles/material-dark.css";
@import "@syncfusion/ej2-vue-popups/styles/material-dark.css";
*/

.content{
  justify-content: center;
  display: grid;
  align-items: center;
  flex-direction: column;
}
</style>
