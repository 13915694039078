import { RouteRecordRaw } from "vue-router";
import OrganisationStructureListView from "@/modules/OrganisationStructure/pages/OrganisationStructureListPage.vue";
import CreateView from "@/modules/OrganisationStructure/pages/CreatePage.vue";
import DetailsView from "@/modules/OrganisationStructure/pages/DetailsPage.vue";
import ProductPriceEditPage from "@/modules/OrganisationStructure/pages/ProductPriceEditPage.vue";

const organisationStructureRoutes: Array<RouteRecordRaw> = [
  {
    path: "/organisationstructure",
    children: [
      {
        path: "list/:type/:level?",
        component: OrganisationStructureListView,
      },
      {
        path: "add/:type/:level?",
        component: CreateView,
      },
      {
        path: "details/:level?",
        component: DetailsView,
      },
      {
        path: "price/:action/:type/:page?",
        component: ProductPriceEditPage,
      },
    ],
  },
];

export default organisationStructureRoutes;
