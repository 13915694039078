export enum HistoryAction {
  /*
          None, SubsequentTaxImport,
        InsuranceRegistrationNumber, InsuranceRegistrationEvb, InsuranceRegistrationNumberEvb, InsuranceRegistrationManual, InsuranceRegistrationRemove,
        InsuranceCancelationRequested, InsuranceCancelationToAllow, InsuranceCancelationAllowed, InsuranceCancelationRejected, InsuranceCancelationRequestRejected, InsuranceCancelationCreated, InsuranceCancelationImported,
        InsuranceCancelationOrdered, InsuranceCancelationRejectedOrdered,
        InsuranceProductChangeCreated, InsuranceProductChangeOrdered,
        Sale, SaleImport,
        Purchase, PurchaseImport,
        VehicleRegistrationIgnore,
        Invoiced, //Order
        InsuranceSubsequentTax, InsuranceSubsequentTaxInvoiced,
        PurchaseInvoiced, PurchaseCredited,
        SaleInvoiced,SaleCredited,//Insurance
        StoreAdded,StoreRemoved,
        InsuranceSubsequentTaxCanceled,//Insurance

        SaleClaimed, PurchaseClaimed, SaleClaimInvoiced,PurchaseClaimInvoiced,//Insurance
        InsuranceBlankLinked,
        CreateInvoiceAttachment, CreateEmailInvoiceAttachment
* */
  OrderSended="OrderSended",OrderReceived="OrderReceived", OrderClaimed="OrderClaimed", OrderPaid="OrderPaid",//Order
  CreateOrderPaymentEmail="CreateOrderPaymentEmail"
}
