import { WebApiAdaptor } from "@syncfusion/ej2-data";
import { DataManager, DataOptions } from "@syncfusion/ej2-data/src/manager";
import { Predicate, Query, QueryOptions } from "@syncfusion/ej2-data/src/query";
import { Ajax } from "@syncfusion/ej2-base";
import { CrudOptions, DataResult } from "@syncfusion/ej2-data/src/adaptors";
import { Api } from "@/shared/services/api";

export class CustomAdaptor extends WebApiAdaptor {
  public filterSearch = false;
  private predicates: Predicate[] = [];

  addPredicate(
    field: string | Predicate,
    operator: string,
    value: string | number | Date | boolean | Predicate | Predicate[] | null,
    ignoreCase?: boolean,
    ignoreAccent?: boolean
  ): void {
    const predicate = new Predicate(
      field,
      operator,
      value,
      ignoreCase,
      ignoreAccent
    );
    this.predicates.push(predicate);
  }

  public removePredicate(predicateField: string): void {
    this.predicates = this.predicates.filter((t) => t.field !== predicateField);
  }

  processResponse(
    data: DataResult,
    ds?: DataOptions,
    query?: Query,
    xhr?: XMLHttpRequest,
    request?: Ajax,
    changes?: CrudOptions /* ...args:any[] */
  ): any {
    // console.log('processResponse')
    const filterSearch = this.filterSearch;
    this.filterSearch = false;
    const original: { result: any[]; count: number } = super.processResponse(
      data,
      ds,
      query,
      xhr,
      request,
      changes /* args as any */
    ) as any;
    const out = !filterSearch
      ? { result: original.result, count: original.count }
      : original.result;
    return out;
  }

  /*
  beforeSend (dm: DataManager, request: XMLHttpRequest, settings: Ajax): void{
    //console.log(request)
  }
*/

  convertToQueryString(request: any, query: Query, dm: DataManager): string {
    /*
    console.log('convertToQueryString')
*/
    const res = super.convertToQueryString(request, query, dm);
    //    console.log(res)
    return res;
  }

  processQuery(dm: DataManager, query: Query, hierarchyFilters?: any[]): any {
    //    console.log('processQuery')
    let a: Predicate;

    if (this.predicates.length > 0) {
      a = Predicate.and(this.predicates[0], this.predicates[0]);
      a.predicates = this.predicates;

      const where: QueryOptions | undefined = query.queries.find(
        (t) => t.fn === "onWhere"
      );
      const predicate = where?.e as Predicate;
      if (predicate) {
        a.predicates = a.predicates.concat(predicate.predicates);
      }
      query.queries = query.queries.filter((t) => t.fn !== "onWhere");
      query.where(a);
    }

    return super.processQuery(dm, query, hierarchyFilters);
  }

  /**
   * Method will trigger before send the request to server side.
   * Used to set the custom header or modify the request options.
   * @param  {DataManager} dm
   * @param  {XMLHttpRequest} request
   * @param  {Ajax} settings
   * @returns void
   */
  beforeSend(dm: DataManager, request: XMLHttpRequest, settings: Ajax): void {
    super.beforeSend(dm, request, settings);
    request.setRequestHeader("Authorization", "Bearer " + Api.token);
    //console.log(request);
  }

  onEachWhere(filter: Predicate, query: Query, requiresCast?: boolean): string {
    /*
    console.log('onEachWhere')
    console.log(filter)
*/

    // remove null or undefined
    if (
      filter.isComplex &&
      filter.condition === "or" &&
      filter.predicates.some(
        (t) => !t.isComplex && t.operator === "equal" && t.value === undefined
      ) &&
      filter.predicates.some(
        (t) => !t.isComplex && t.operator === "equal" && t.value === null
      )
    ) {
      // console.log(filter)

      // eslint-disable-next-line
      filter = filter.predicates.find(
        (t) => !t.isComplex && t.operator === "equal" && t.value === null
      )!;
      // console.log('wdqwdqd')
    }

    /*
    if (!filter.isComplex && filter.operator === 'equal' && filter.value === undefined) {
      return ''
    }
*/

    /*
    console.log(query)
    console.log(requiresCast)
*/
    const res = super.onEachWhere(filter, query, requiresCast);
    /*
    console.log('end')1
    console.log(res)
*/
    return res;
  }
}

export class PivotCustomAdaptor extends WebApiAdaptor {
  processResponse(
    data: DataResult,
    ds?: DataOptions,
    query?: Query,
    xhr?: XMLHttpRequest,
    request?: Ajax,
    changes?: CrudOptions /* ...args:any[] */
  ): any {
    const original: { result: any[]; count: number } = super.processResponse(
      data,
      ds,
      query,
      xhr,
      request,
      changes /* args as any */
    ) as any;
    //    const out = { items: original.result, count: original.count }
    const out = original.result;
    return out;
  }
}
