import {InsuranceType} from "@/shared/model/insuranceType";

export default class Insurance {
  id?: string;
  insuranceNumber?: string;
  grossPrice?: number;
  insuranceTax?: number;
  insuranceAmount?: number;
  shortPrice?: number;
  createDate?: Date;
  productTypeId?: string;
  carTypeId?: string;
  manufacturerId?: string;
  manufacturerName?: string;
  activationCode?: string;
  activationDate?: Date;
  evb?: string;
  evbValidityDate?: Date;
  activationDuration?: number;
  territoryTypeId?: string;
  formNumber?: string;
  validityDay?: number;
  vehicleRegistrationId?: string;
  insuranceType?: InsuranceType;
  hasCancellationSupplier?: boolean;
  hasCancellationPerson?: boolean;
  productDescription?: string;
  inceptionDate ?: Date;
  expiryDate ?: Date;
  insuranceExportBlankId?: string;
}

export function sortInsuranceByNumber(array: Insurance[]): Insurance[] {
  return array.sort((a, b) => {
    const r = a.insuranceNumber?.localeCompare(b.insuranceNumber ?? "")
    return r ?? 0
  });
}

