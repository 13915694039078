import { Api } from "@/shared/services/api";

export class ApiReport extends Api {
  async purchaseSale(from:Date,till:Date): Promise<string|undefined> {
    try {
      const request = {
        from : from,
        till: till
      };
      const resp = await this.post("/PurchaseSaleReport", request);
      if (resp.successful && resp.id) {
        return resp.id;
      }
      return undefined;
    } finally {
    }
  }
}

const apiReport = new ApiReport();

export default apiReport;

/*
  async InsuranceActivate(
  ): Promise<|> {
    try {
      const request = {
        loginName: loginName,
        personId: personId,
        actionPersonId: actionPersonId,
      };
      const resp = await this.post("/AccountInsertRequest", request);
      if (resp.successful) {
        return true;
      }
      return undefined;
    } finally {
      this.hideSpinner();
    }
}
* */
