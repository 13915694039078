export enum OrderType {
  Order = "Order",
  Invoice = "Invoice",
}

export enum OrderStatus {
  Editing = "Editing",
  Completed = "Completed",
  Sended = "Sended"
}

export default class OrderHead {
  id?: string;
  orderNumber?: string;
  orderType?: OrderType;
  insuranceTaxAmount?: number;
  orderAmount?: number;
  invoiced?: boolean;
  paid?: boolean;
  orderedDate?: Date;
  addressLine1?: string;
  addressLine2?: string;
  addressStreet?: string;
  addressHouseNumber?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressCountryCode?: string;
  orderedByPersonName?: string;
  supplierName?: string;
  personName?: string;
  supplierId?: string;
  personId?: string;
  statusId?: string;
  invoiceCircle?:string
}
