export enum Action {
  ConfigShopManage = "ConfigShopManage",
  AccountManage = "AccountManage",
  All = "all",
  ProductPriceManage = "ProductPriceManage",
  Sale = "Sale",
  Purchase = "Purchase",
  Admin = "admin",
  WebSiteManage = "WebSiteManage",
  OrganisationStructureManage = "OrganisationStructureManage",
  GdvAccessManage = "GdvAccessManage",
  BordereauManage = "BordereauManage",
  OrderingBlocking = "OrderingBlocking",
  SuperAccountManage = "SuperAccountManage"
}

export const superActions = [
  {id: Action.OrderingBlocking, checked: false, description: "Bestellmöglichkeit sperren"},
  {
    id: Action.SuperAccountManage,
    checked: false,
    description: "Alle Berechtigungen verwalten",
  },

]

export const allRootActions = [
  {id: Action.Sale, checked: false, description: "Verkauf"},
  {id: Action.Purchase, checked: false, description: "Einkauf"},
  {
    id: Action.OrganisationStructureManage,
    checked: false,
    description: "Organisationsstruktur verwalten",
  },
  {
    id: Action.ProductPriceManage,
    checked: false,
    description: "Produktpreise verwalten",
  },
  {
    id: Action.BordereauManage,
    checked: false,
    description: "Bordereau verwalten",
  },
  {
    id: Action.AccountManage,
    checked: false,
    description: "Benutzer verwalten",
  },
  {
    id: Action.GdvAccessManage,
    checked: false,
    description: "GDV-Zugänge verwalten",
  },
  {
    id: Action.ConfigShopManage,
    checked: false,
    description: "Shop-Einstellungen verwalten",
  },
  {
    id: Action.WebSiteManage,
    checked: false,
    description: "Portal-Einstellungen verwalten",
  },
];

export const allActions = [
  /*
  { id: Action.Sale, checked: false, description: "Verkauf" },
*/
  {id: Action.Purchase, checked: false, description: "Einkauf"},
  {
    id: Action.OrganisationStructureManage,
    checked: false,
    description: "Organisationsstruktur verwalten",
  },
  {
    id: Action.ProductPriceManage,
    checked: false,
    description: "Produktpreise verwalten",
  },
  {
    id: Action.AccountManage,
    checked: false,
    description: "Benutzer verwalten",
  },
  /*
  {
    id: Action.WebSiteManage,
    checked: false,
    description: "Einstellungen verwalten",
  },
*/
];

export const usNatoActions = [
  /*
  { id: Action.Sale, checked: false, description: "Verkauf" },
*/
  /*
    {
      id: Action.OrganisationStructureManage,
      checked: false,
      description: "Organisationsstruktur verwalten",
    },
  */
  {
    id: Action.OrganisationStructureManage,
    checked: false,
    description: "Organisationsstruktur verwalten",
  },
  {
    id: Action.AccountManage,
    checked: false,
    description: "Benutzer verwalten",
  },
  {
    id: Action.BordereauManage,
    checked: false,
    description: "Bordereau verwalten",
  },
  /*
  {
    id: Action.WebSiteManage,
    checked: false,
    description: "Einstellungen verwalten",
  },
*/
];

export const lossAdjusterActions = [
  /*
  { id: Action.Sale, checked: false, description: "Verkauf" },
*/
  /*
    {
      id: Action.OrganisationStructureManage,
      checked: false,
      description: "Organisationsstruktur verwalten",
    },
  */
  {
    id: Action.OrganisationStructureManage,
    checked: false,
    description: "Organisationsstruktur verwalten",
  },
  {
    id: Action.AccountManage,
    checked: false,
    description: "Benutzer verwalten",
  },
  {
    id: Action.BordereauManage,
    checked: false,
    description: "Bordereau verwalten",
  },
  /*
  {
    id: Action.WebSiteManage,
    checked: false,
    description: "Einstellungen verwalten",
  },
*/
];
