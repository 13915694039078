
interface Versicherung {
  datumVersicherungsbeginn: Date,
  versicherungsscheinnummer: string,
  name1: string,
  name2: string,
  name3: string,
  name4: string,
  agenturVersicherung: string,
  geschaeftsstelleVersicherung: string,
  versicherungsunternehmensnummer: string,
  interneVermerkeVersicherung: string,
  unternehmensart: string,
  unternehmensartDescription?: string,
}

interface NatuerlichePerson {
  nachname: string,
  vorname: string,
  geschlecht:string,
}

interface JuristischePerson {
  nameJuristischePerson: string,
}

interface Vereinigung {
  nameVereinigung: string,
  natuerlichePerson: NatuerlichePerson,
}

interface Anschrift {
  adressierungszusaetze: string,
  hausnummer: 0,
  strasse: string,
  staat: string,
  wohnort: string,
  postleitzahlPostfach: string,
  postfach: string,
  postleitzahl: string,
  gemeindeschluessel: string,
  strassenschluessel: string
}

interface Versicherungsnehmer {
  natuerlichePerson?: NatuerlichePerson,
  juristischePerson?: JuristischePerson,
  vereinigung?: Vereinigung,
  anschrift?: Anschrift,
  kundennummerVu: string,
  kundennummerVm: string,
}

interface Halter {
  natuerlichePerson?: NatuerlichePerson,
  juristischePerson?: JuristischePerson,
  vereinigung?: Vereinigung,
  anschrift?: Anschrift,
  halterAbweichendVonVersicherungsnehmer: boolean,
}

interface Kennzeichen {
  datumKennzeichenGueltigBis: Date,
  zeitpunktZuteilungKennzeichen: Date,
  datumAblaufKennzeichenreservierung: Date,
  betriebszeitraumBis: string,
  betriebszeitraumVon: string,
  merkmalWechselkennzeichen: true,
  kennzeichenBisher: string,
  kennzeichenString: string,
  kennzeichenOhneSpace: string,
  kennzeichenBisherOhneSpace: string,
  merkmalKennzeichenart: string,
  listeMerkmalKennzeichenart: Array<string>,

  ablauftageKurzzeitkennzeichen: 0,
}

interface Fahrzeug {
  merkmalOldtimer: true,
  hubraum: string,
  nennleistungKw: string,
  textAufbau: string,
  textFahrzeugklasse: string,
  schluesselAufbau: string,
  schluesselFahrzeugklasse: string,
  schluesselVarianteVersion: string,
  schluesselTyp: string,
  hersteller: string,
  schluesselHersteller: string,
  verwendungFahrzeug: string,
  fahrzeugart: string,
  fahrzeugartDescription: string,
  fahrzeugidentifizierungsnummer: string,
  listeVerwendungFahrzeug: Array<string>
}

interface Behoerde {
  zeitpunktBearbeitung: Date,
  zustaendigeBehoerdeKreisschluessel: string,
  zustaendigeBehoerdeZusatzziffer: string
}

interface VbVerarbeitungshistorieElement {
  behoerde: Behoerde,
  vbStatus: string,
  verarbeitungsschritt: string,
  zeitpunktVerarbeitungsschritt: Date,
  vbStatusString: string
}

export default class RechercheMessage {

  gdvMessageMode?: string

  versicherungsbestaetigungsnummer?: string
  vbStatus?: string
  vbEinstelldatum?: Date
  nutzerkennung?: string


  zulassungsfahrten?: string
  vbAbrufbarBis?: Date
  vbArt?: string
  vbArtDescription?: string
  vbFormular?: string
  vbStatusString?: string

  versicherung?: Versicherung

  vorgangsnummer?: string
  vuInterneVermittlernummer?: string
  gdvVermittlernummer?: string

  versicherungsnehmer?: Versicherungsnehmer

  halter?: Halter

  kennzeichen?: Kennzeichen

  fahrzeug?: Fahrzeug

  listeNachrichtenId?: Array<string>
  zeitstempel?: Date
  listeVbVerarbeitungshistorie?: Array<VbVerarbeitungshistorieElement>
} 