import { RouteRecordRaw } from "vue-router";
import GdAccessListPage from "@/modules/Gdv/pages/GdvAccessListPage.vue";
import GdvAccesEditPage from "@/modules/Gdv/pages/GdvAccesEditPage.vue";
import PasswordChangePage from "@/modules/Gdv/pages/PasswordChangePage.vue";

const gdvRoutes: Array<RouteRecordRaw> = [
    {
        path: "/gdvaccess",
        component: GdAccessListPage
    },
    {
        path: "/gdvacess/:action",
        component:GdvAccesEditPage
    },
    {
        path: "/gdvaccess_change_pin",
        component: PasswordChangePage
    }
];

export default gdvRoutes;
