<template>
  <footer
    class="footer"
    style="width: 90%"
  >
    <sog-flex-row style="justify-content: flex-end">
      <router-link
        class="footerlink"
        to="/datenschutz"
      >
        Datenschutz
      </router-link>
      <router-link
        class="footerlink"
        to="/verbraucherinformationen"
      >
        Verbraucherinformationen
      </router-link>
      <router-link
        class="footerlink"
        to="/impressum"
      >
        Impressum
      </router-link>
      <span
        id="ppms_cm_privacy_settings"
        class="ppms_cm_privacy_settings_widget"
        data-editor-centralize="true"
        data-main-container="true"
        data-root="true"
      >
        <a
          id="ppms_cm_privacy_settings_button"
          class="footerlink"
        >Cookie Settings</a>
      </span>
    </sog-flex-row>
  </footer>
</template>

<script>
import SogFlexRow from "@/shared/ui/SogFlexRow.vue";
export default {
  name: 'MyFooter',
  components: { SogFlexRow }
};
</script>


<style scoped>
.footer {
  /*
  background-color: #1d1d1d;
  */
  /*
  background-color: #1d1d1d;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 120px;
  */
}

.title {
  color: #fff;
}

.description {
  color: #fff;
}

.footerlink,
.footerlink:hover {
  color: #fff;
}
</style>
