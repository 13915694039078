/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from "axios";
import notificationService from "@/shared/services/NotificationService";
import { ref } from "vue";
import ConfigItem from "@/shared/model/ConfigItem";
import copyTo from "@/shared/utils/CopyTo";

export class Api {
  public static token = "";
  public static baseUrl = "";

  public static logout: (() => void) | undefined;



  constructor() {
    Api.baseUrl = "http://localhost:8448";

    if (document.location.hostname === "localhost") {
      if(process.env.VUE_APP_LOCAL)
        Api.baseUrl = "/iw-web";
      else{
        Api.baseUrl = "https://test.agent22.eu/iw-web-test";
      }
    } else if (document.location.hostname.substring(0, 4) === "test") {
      Api.baseUrl = "/iw-web-test";
    } else {
      Api.baseUrl = "/iw-web";
    }
    this._configApi = {
      baseURL: Api.baseUrl,
      responseType: "json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        //"Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded'
        Authorization: "Bearer " + Api.token,
      },
    };
  }

  private _configApi: AxiosRequestConfig = {
    baseURL: "iw-web/",
    responseType: "json",
    headers: {
      // 'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded'
      Authorization: "Bearer " + Api.token,
    },
  };

  protected getConfigApi(): AxiosRequestConfig {
    /*
    const store = useStore()

    const { token } = storeToRefs(store)
    console.log(token.value)
*/

    return {
      ...this._configApi,
      headers: { Authorization: "Bearer " + Api.token },
    };
  }

  protected async post(url: string, request: any, checkSuccessful = true) {
    try {
      const response = await axios.post(url, request, this.getConfigApi());
      const { data } = response;
      if (checkSuccessful && !data.successful) {
        notificationService.error(data.message);
      }
      return data;
    } catch (error: any) {
      const a: string =
        error.response?.status + ": " + error.response?.statusText;
      console.log(error);
      if (error.response?.status === 401) {
        notificationService.errorThen(a, () => {
          if (Api.logout) {
            Api.logout();
          }
        });
      } else if (checkSuccessful)
        notificationService.error(a);
      return { successful: false, message: a };
    }
  }

  protected delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public static uploaderSaveUrl(){
    return Api.baseUrl + '/UploadTempFile'
  }

  public static uploaderRemoveUrl(){
    return Api.baseUrl + '/RemoveTempFile'
  }

  async uploadTempFile(fileName: string, content: Blob):Promise<string|undefined> {
    try {
      const formData = new FormData();
//    formData.append('photo', _capturedPhoto.value, 'captured-photo.jpg');
      formData.append('file', content, fileName);

      // POST-Anfrage an den Server senden (Beispiel mit Fetch API)

      const resp = await fetch(Api.uploaderSaveUrl(), {
        method: 'POST',
        body: formData
      });

      if (resp.ok) {
        const res = await resp.json();
        if (res.successful && res.id) {
          return res.id;
        } else {
          notificationService.error(res.message)
        }
      } else {
        notificationService.error(resp?.status + ": " + resp?.statusText)
      }
    } catch (e) {
      notificationService.error("'Fehler beim Hochladen", e)
    }
  }


  async loadConfig(keys: string[]): Promise<ConfigItem[] | undefined> {

    const t_request = {
      keys: keys,
    }

    const t_resp = await this.post("ConfigLoad", t_request);
    if (t_resp.successful) {
      return t_resp.items.map((t:any)=>{
        const t_item = new ConfigItem()
        copyTo(t,t_item)
        return t_item
      })


    }

    return undefined;
  }

  async updateConfig(item: ConfigItem): Promise<boolean> {

    const t_request = {
      configItem: item,
    }

    const t_resp = await this.post("ConfigUpdate", t_request);
    return t_resp.successful;

  }

}

export const gridActionFailure = function (args: any) {
  console.log(args);
  let f = undefined;
  const message = ref(args.error?.error.responseText);
  if (args.error?.error?.status === 401) {
    message.value = "Bitte melden Sie sich an.";
    f = () => {
      if (Api.logout) {
        Api.logout();
      }
    };
  }
  notificationService.errorThen(message.value, f);
};
