import Product from "@/shared/model/product";
import {ProductType} from "@/shared/model/productType";
import {TerritoryType} from "@/shared/model/territoryType";
import {CarType} from "@/shared/model/carType";

export default class ProductPrice extends Product {
  id?: string;
  supplierId?: string;
  supplierName?: string;
  grossPrice = 0;
  shortPrice?: number;
  tax?: number;
  taxAmount?: number;
  invoiceTypeId?: string;
  invoiceCircleId?: string;
  fromDate?: Date;
  tillDate?: Date;
  minSaleGrossPrice?: number;
  minSaleShortPrice?: number;

  getSortIndex(): number {
    let i: number;
    switch (this.productTypeId) {
      case ProductType.Kzk:
        i = 1000;
        if (this.territoryTypeId === TerritoryType.Germany) {
          i += 100;
        } else {
          i += 200;
        }
        switch (this.carTypeId) {
          case CarType.PassengerCar:
            i += 10;
            break;
          case CarType.Truck:
            i += 20;
            break;
          case CarType.Trailer:
            i += 30;
            break;
          case CarType.NoLimited:
            i += 40;
            break;
          default:
            i += 50;
            break;
        }
        i += this.validityDay ?? 0;
        break;
      case ProductType.Zoll:
        i = 2000;
        switch (this.carTypeId) {
          case CarType.PassengerCar:
            i += 100;
            break;
          case CarType.Truck:
            i += 200;
            break;
          case CarType.Trailer:
            i += 300;
            break;
          case CarType.NoLimited:
            i += 400;
            break;
          default:
            i += 500;
            break;
        }
        i += this.validityDay ?? 0;
        break;
      case ProductType.Tz:
        i = 3000;
        switch (this.carTypeId) {
          case CarType.PassengerCar:
            i += 100;
            break;
          case CarType.Truck:
            i += 200;
            break;
          case CarType.Trailer:
            i += 300;
            break;
          case CarType.NoLimited:
            i += 400;
            break;
          default:
            i += 500;
            break;
        }
        break;
      case ProductType.ExportBlank:
        i = 4000;
        break;
      case ProductType.Carplate:
        i = 5000;
        break;
      case ProductType.Addition:
        i = 6000;
        break;
      default:
        i = 7000;
        break;
    }
    return i * 1000 + this.grossPrice;
  }
}
