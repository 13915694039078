export enum OrgStructureCluster {
  Customer = "customer",
  Branch = "branch",
  Employee = "employee",
  Supplier = "supplier",
  Manufacturer = "manufacturer",
  LossAdjuster = "lossadjuster",
  All = "all",
}
export function orgStructureClusterName(value?: OrgStructureCluster) {
  switch (value) {
    case OrgStructureCluster.Customer:
      return "Kunde";
    case OrgStructureCluster.Branch:
      return "Filiale";
    case OrgStructureCluster.Employee:
      return "Mitarbeiter";
    case OrgStructureCluster.Supplier:
      return "Lieferant";
    case OrgStructureCluster.Manufacturer:
      return "Versicherer/Hersteller";
    case OrgStructureCluster.LossAdjuster:
      return "Schadenregulierer";
    case OrgStructureCluster.All:
      return "Alle";
  }
  return value ?? "";
}
