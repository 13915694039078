import {  carTypeName } from "@/shared/model/carType";
import { territoryTypeName } from "@/shared/model/territoryType";
import { productTypeName } from "@/shared/model/productType";

export default class Product {
  productName?: string;
  productTypeId?: string;
  carTypeId?: string;
  territoryTypeId?: string;
  validityDay?: number;
  personId?: string;
  manufacturerId?: string;
  manufacturerName?: string;
  productKey?: string;
  saleTypes: string[]=[];
  productRefId?:string

  get isJustInTime():boolean{
    return this.saleTypes?.includes("JustInTime") ?? false;
  }
  get isStoreExportBlank():boolean{
    return this.saleTypes?.includes("StoreExportBlank") ?? false;
  }
  get isStore():boolean{
    return this.saleTypes?.includes("Stock") ?? false;
  }

  get isInsurance():boolean{
    return this.productTypeId === "Zoll"||this.productTypeId === "Kzk"||this.productTypeId === "Tz";
  }


  get productKeyWithoutManufacturer() {
    return (
      this.productTypeId +
      "|" +
      this.carTypeId +
      "|" +
      this.territoryTypeId +
      "|" +
      this.validityDay
    );
  }

  get carTypeName() {
    return carTypeName(this.carTypeId);
  }

  get territoryTypeName() {
    return territoryTypeName(this.territoryTypeId);
  }

  get productTypeName() {
    return productTypeName(this.productTypeId ?? "");
  }
}
