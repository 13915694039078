export default class VehicleRegistration {
  id?: string
  insuranceId?: string
  insuranceNumber?: string
  evb?: string
  memo?: string
  gdvMessageIds?: string
  versicherungsBestaetigungsNummer?: string
  versicherungsScheinnummer?: string
  datumVersicherungsBeginn?: Date
  fahrzeugIdentifizierungsNummer?: string
  fahrzeugHersteller?: string
  fahrzeugArt?: string
  kennzeichen?: string
  kennzeichenDatumGueltigBis?: Date
  kennzeichenZeitpunktZuteilung?: Date
  halterName?: string
  halterAnschrift?: string
  halterPostleitzahl?: string
  halterWohnort?: string
  halterStaat?: string
  halterStaatName?: string
  halterStrasse?: string
  halterHausnummer?: string
  halterVorname?: string
  halterNachname?: string
  halterGeschlecht?: string
  gdvStorno?: string
  ignore?: string
  fahrzeugArtDescription?: string
  nennleistungKw?: string
  hubraum?: string
  schluesselHersteller?: string
  schluesselTyp?: string
  schluesselVarianteVersion?: string
  schluesselFahrzeugklasse?: string
  schluesselAufbau?: string
  textFahrzeugklasse?: string
  textAufbau?: string
  vertragsEnde?: Date
  statusId?: string
  statusDescription?: string
}
