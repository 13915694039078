export enum IdType {
  Order,
  OrderItem,
  Insurance,
  VehicleRegistration,
  Person,
  Evb,
  InsuranceExportBlank,
  InsuranceCancelation = 7,
}
