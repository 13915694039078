import { defineStore } from "pinia";
import {reactive} from "vue";

export const useOrderStore = defineStore("order", () => {
  const listPage = reactive({number: "", overdue: "all",personId:"all"})

  return {
    listPage
  };

});
