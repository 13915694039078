import {ProgressInfo} from "@/shared/model/ProgressInfo";
import delay from "@/shared/utils/delay";
import {HubConnection, HubConnectionBuilder, HubConnectionState, IRetryPolicy, RetryContext} from "@microsoft/signalr";
import {Api} from "@/shared/services/api";

interface GroupItem {
  group:string
  on:(group:string, progressInfo: ProgressInfo) => void
}

class ReconnectPolicy implements IRetryPolicy {
  //private readonly _retryDelays: (number | null)[];

  public nextRetryDelayInMilliseconds (retryContext: RetryContext): number | null {
    return ((retryContext.previousRetryCount % 3) + 1) * 3000
  }
}

export class ProgressService {
  private _signalProgress?:HubConnection = undefined

  public _groups:GroupItem[] = []

  private async getSignalProgress ():Promise<HubConnection> {
    if (this._signalProgress && this._signalProgress.state === HubConnectionState.Connected) {
      return this._signalProgress
    }
    if (this._signalProgress && (this._signalProgress.state === HubConnectionState.Connecting || this._signalProgress.state === HubConnectionState.Reconnecting)) {
      while (this._signalProgress && (this._signalProgress.state === HubConnectionState.Connecting || this._signalProgress.state === HubConnectionState.Reconnecting)) {
        await delay(500)
      }
    }
    if (this._signalProgress && this._signalProgress.state === HubConnectionState.Connected) {
      return this._signalProgress
    }

    this._signalProgress = new HubConnectionBuilder()
      //      .withUrl('http://localhost/bs/signalr/progress', HttpTransportType.WebSockets)
      .withUrl(Api.baseUrl+'/signalr/progress'  ,{
        headers: {
          "Authorization": "Bearer " + Api.token
        },
/*

        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
*/
      } )
      // .configureLogging('trace')
      .configureLogging('debug')
      .withAutomaticReconnect(new ReconnectPolicy())
      .build()
    this._signalProgress.onreconnected(() => {
      this.sendAddToGroup(this._groups.map(g => g.group), true)
    })
    this._signalProgress.on('info', this.on)
    await this._signalProgress.start()
    return this._signalProgress
  }

  public on (group:string, info: ProgressInfo) {
    /*
    console.log(group)
    console.log(info?.groupId)
*/
    const array = progressService._groups.filter(g => g.group === group)
    array.forEach(t => t.on(group, info))
  }

  private async sendAddToGroup (ids: string[], last: boolean | null) {
    const request = { GroupIds: ids, Last: last }
    const signal = await this.getSignalProgress()
    await signal.send('AddToGroup', request)
  }

  public async addToGroup (ids: string[] | null, last: boolean | null, on: (group:string, items: ProgressInfo) => void) {
    if(ids===null)
      return
    ids.forEach(id => {
      this._groups.push({ group: id, on: on })
    })
    await this.sendAddToGroup(ids, last)
  }

  public async removeFromGroup (ids:string[], on: (group:string, items: ProgressInfo) => void) {
    ids.forEach(id => {
      this._groups = this._groups.filter(g => g.group !== id && g.on !== on)
    })

    const request = { GroupIds: ids, Last: false }
    const signal = await this.getSignalProgress()
    await signal.send('RemoveFromGroup', request)
    if (this._groups.length === 0) {
      await signal.stop()
    }
  }
}
const progressService = new ProgressService()

export default progressService
