<template>
  <div
    id="main"
    class="content"
  >
    <div
      flat
      class="text-center mt-10 pt-10"
    >
      <span
        class="primary--text"
        style="font-size: 17px !important; margin-right: 2px; color: white"
      >
        <strong>Etwas ist schief gelaufen.</strong>
        <br>
        <strong>We apologize for the inconvenience and thank you for your patience.</strong>
      </span>
    </div>
    <br>
    <div class="text-center">
      <video
        src="../../public/maintenance.mp4"
        width="1024"
        autoplay
        muted
        loop
        type="video/mp4"
        class="video"
      >
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>
<script>



export default {
  name: "AppError",
}
</script>
<style scoped>

.content{
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.video{
  max-width: 1024px;
  width: 90%    !important;
  height: auto   !important;
}
</style>
