
export enum InsuranceType {
  Kzk = "Kzk",
  Zoll = "Zoll",
  Tz = "Tz",
}

export function insuranceTypeName(value: string) {
  switch (value) {
    case InsuranceType.Kzk:
      return "Kurzzeitkennzeichen";
    case InsuranceType.Zoll:
      return "Ausfuhrkennzeichen";
    case InsuranceType.Tz:
      return "Tageszulassung";
  }
  return value;
}
