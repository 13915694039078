import { RouteRecordRaw } from "vue-router";
import VbDetailsPage from '@/modules/VbRecherche/pages/VbRechercheDetailsPage.vue'

const vbRechercheRoutes: Array<RouteRecordRaw> = [
  {
    path: '/vbrecherche',
    children: [
      {
        path: 'details/:evb',
        component: VbDetailsPage,
        props: true,
      }
    ]
  },
]

export default vbRechercheRoutes