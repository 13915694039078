import { Api } from "@/shared/services/api";

export class ApiBordereau extends Api {
    async initExport(periodId: string,partnershipIds:string[],voucher:boolean,csv:boolean,ftp:boolean): Promise<string | undefined> {
        if (!periodId) {
            return undefined
        }
        const resp = await this.post("WakamBordereauInitExport", {
            periodId: periodId,
            partnershipIds:partnershipIds,
            voucher:voucher,
            csv:csv,
            ftp:ftp
        });
        if (resp.successful && resp.id) {
            return resp.id;
        }
    }

    async lock(periodId?: string): Promise<boolean | undefined> {
        if (!periodId) {
            return undefined
        }
        const resp = await this.post("WakamBordereauLockPeriod", {
            id: periodId,
        });
        if(resp.successful){
            await this.post("WakamBordereauSendEmail", {
                periodId: periodId,
            });
        }
        return resp.successful;
    }

    async unlock(periodId?: string): Promise<boolean | undefined> {
        if (!periodId) {
            return undefined
        }
        const resp = await this.post("WakamBordereauUnlockPeriod", {
            id: periodId,
        });
        return resp.successful;
    }

    async sendMail(periodId: string, fileId: string ) {
        await this.post("WakamBordereauSendEmail", {
            periodId: periodId,fileId:fileId
        });
    }
}

const apiBordereau = new ApiBordereau();

export default apiBordereau;

