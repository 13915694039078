
import { defineStore } from "pinia";
import {reactive, ref} from "vue";


export const useVehicleRegistrationStore = defineStore("vr", () => {

        const vehicleRegistrationId = ref<string | undefined>(undefined);

        const listPageFilter2 = reactive({  imBestand: "all", ignorierte: "all"})
        const listPageFilter = reactive({ number : "", kennzeichen : "", FIN:""})


        return {vehicleRegistrationId,listPageFilter2: listPageFilter2,listPageFilter};
});