import {InsuranceType} from "@/shared/model/insuranceType";
import {CarType, carTypeName} from "@/shared/model/carType";
import {TerritoryType, territoryTypeName} from "@/shared/model/territoryType";
import {ValidityDay} from "@/shared/model/validityDay";
import getGuid from "@/shared/utils/getGuid";
import {productTypeName} from "@/shared/model/productType";
import ProductPrice from "@/shared/model/productPrice";
import InsuranceExportBlank from "@/shared/model/InsuranceExportBlank";
import {fillNumberWithZeros} from "@/shared/utils/NumberUtils";
import Insurance from "@/shared/model/Insurance";

export default class ShoppingCartItem {
  constructor() {
    this.id = getGuid();
  }

  productPrice?: ProductPrice;
  readonly id: string;

  insuranceExportBlanks: InsuranceExportBlank[] = []
  insurances: Insurance[] = []

  count = 1;
  grossPrice = 0;
  prefix?: string
  startNumber?: number
  digitCount?: number;

  get insuranceType(): InsuranceType {
    return (
      (this.productPrice?.productTypeId as InsuranceType) ?? InsuranceType.Kzk
    );
  }

  get carType(): CarType {
    return (this.productPrice?.carTypeId as CarType) ?? CarType.PassengerCar;
  }

  manufacturerId = "";

  get territoryType(): TerritoryType | undefined {
    return this.productPrice?.territoryTypeId as TerritoryType;
  }


  get validityDay(): ValidityDay | undefined {
    return this.productPrice?.validityDay as ValidityDay;
  }

  get validityDayNumber(): number | undefined {
    return this.productPrice?.validityDay;
  }

  get amount(): number {
    const tax = this.productPrice?.tax ?? 0;
    const t_taxAmount = Number(
      ((this.grossPrice * tax) / (100 + tax)).toFixed(2)
    );
    return ((this.productPrice?.shortPrice ?? 0) + t_taxAmount) * this.count;
  }

  get insuranceTypeName(): string {
    return productTypeName(this.insuranceType);
  }

  get productDescription(): string {
    let t_res = "";
    switch (this.insuranceType) {
      case InsuranceType.Kzk:
        const t_validityDay = ((this.validityDayNumber ?? 5) === 1) ? " Tag" : " Tage"
        t_res += carTypeName(this.carType) + " " + territoryTypeName(this.territoryType) + " " + this.validityDayNumber + t_validityDay + " ";
        break;
      case InsuranceType.Zoll:
        t_res += carTypeName(this.carType) + " " + this.validityDay + " Tage ";
        break;
      case InsuranceType.Tz:
        t_res += carTypeName(this.carType) + " ";
        break;
    }
    if (this.productPrice?.productTypeId === "ExportBlank") {
      if (this.insuranceExportBlanks.length === 0) {
        const t_start = fillNumberWithZeros(this.startNumber ?? 0, this.digitCount ?? 0)
        const t_end = fillNumberWithZeros(((this.startNumber ?? 0) + (this.count ?? 0) - 1), this.digitCount ?? 0)
        t_res += this.prefix + t_start + " - " + this.prefix + t_end
      }
    }
    return t_res;
  }

  get manufacturerName(): string {
    return this.productPrice?.manufacturerName ?? "";
  }

  get minGrosPrice(): number | undefined {
    return this.productPrice?.minSaleGrossPrice ?? this.productPrice?.grossPrice
  }

}
