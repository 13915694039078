<template>
  <div class="e-card">
    <slot />
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>
.e-card {
  justify-content: start;
}

@media (max-width: 450px) {
  .e-card {
    min-width: 100px;
    width: 100%;
  }
}

@media (min-width: 450px) {
  .e-card {
    min-width: 100px;
    width: auto;
  }
}
</style>
