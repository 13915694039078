<template>
  <div class="content-flex-column">
    <slot />
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>
.content-flex-row {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 1em;
  flex-wrap: wrap;
}

.content-flex-column {
  justify-content: center;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 1em;
  flex-wrap: wrap;
}

</style>
