import {HistoryDetailsType} from "@/shared/model/HistoryDetailsType";

export default class HistoryDetails {
  $type = ""

  public getType(): HistoryDetailsType | undefined {
    return this.$type as HistoryDetailsType;
    /*

        switch (this.$type) {
          case HistoryDetailsType.OrderHeadInfo:
            return HistoryDetailsType.OrderHeadInfo
          case HistoryDetailsType.String:
            return HistoryDetailsType.String
          case HistoryDetailsType.OrderItemToInsuranceInfo:
            return HistoryDetailsType.OrderItemToInsuranceInfo

        }
        return undefined;
    */
  }

}

