import { defineStore } from "pinia";
import {reactive} from "vue";
import ProductPrice from "@/shared/model/productPrice";
import Person from "@/shared/model/person";

export const useOrganisationStructureStore = defineStore("organisation_structure", () => {
  const detailsPage = reactive({currentTabIndex : 0,ownerPersonId:"",ownerPersonName:""})
  const createPage = reactive({ownerPersonId:"",ownerPersonName:""})
  const listPage = reactive({secondCurrentPersonId: "", secondCurrentPersonName: "", search : ""})
  const productPriceEditPage:{productPrices?:ProductPrice[],person?:Person,basePrice:ProductPrice|undefined,price:ProductPrice|undefined} = reactive({productPrices:undefined,person:undefined,basePrice:undefined,price:undefined})

  function clearProductPriceEditPage() {
    productPriceEditPage.productPrices = undefined;
    productPriceEditPage.basePrice = undefined
    productPriceEditPage.price = undefined;
    productPriceEditPage.person = undefined
  }

  return { detailsPage,listPage,createPage,productPriceEditPage,clearProductPriceEditPage};
});
