import {Api} from "@/shared/services/api";
import ListFile from "@/shared/model/ListFile";

export class FileUtilsApi extends Api {

    public async  listFileStore(refId: string):Promise<ListFile[]| undefined>{

        const request = {
            refId: refId
        };
        const resp = await this.post("/FileListRequest", request);
        if(resp.successful && resp.result) {
            return resp.result;
        }

        return undefined;

    }
   public async  deleteFile(id: string):Promise<boolean|undefined>{
        const request = {
            id: id
        };
        const resp = await this.post("/FileRemoveRequest", request);
        return resp.successful;
    }
    public async initDownloadFile(id: string):Promise<string | undefined>{
        const request = {
            id: id
        };
        const resp = await this.post("/InitDownloadRequest", request);
        if (resp.successful && resp.id) {
            return resp.id;
        }
        return undefined;
    }
}
const apiFileUtils = new FileUtilsApi();
export default apiFileUtils;

