import {RouteRecordRaw} from "vue-router";
import InsuranceListPage from "@/modules/Insurance/pages/InsuranceListPage.vue";
import EvbActivationPage from "@/modules/Insurance/pages/EvbActivationPage.vue";
import DetailsPage from "@/modules/Insurance/pages/DetailsPage.vue";
import PrintExportInsurancePage from "@/modules/Insurance/pages/PrintExportInsurancePage.vue";

const insuranceRoutes: Array<RouteRecordRaw> = [
  {
    path: "/insurance",
    children: [
      {
        path: "list/:type/:id?",
        component: InsuranceListPage,
        props: true,
      },
      {
        path: "list",
        redirect: "/insurance/list/all",
      },
      {
        path: "evbActivation",
        component: EvbActivationPage,
      },
      {
        path: "details/:id?",
        component: DetailsPage,
        props: true,
      },
      {
        path: "print/export",
        component: PrintExportInsurancePage,
      },
    ],
  },
];

export default insuranceRoutes;
