import {createApp} from "vue";
import App from "./app/App.vue";
import AppStart from "./app/AppStart.vue";
import AppError from "./app/AppError.vue";
import "./registerServiceWorker";
import router from "./app/routes";
import {createPinia} from "pinia";
import {
  registerLicense,
  setCulture,
  setCurrencyCode,
  loadCldr,
} from "@syncfusion/ej2-base";
import {createVuetify} from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import {useAccountStore} from "@/stores/accountStore";
import Keycloak, {KeycloakConfig} from "keycloak-js";
import apiAuth from "@/shared/services/apiAuth";

import * as numbers from "cldr-data/main/de/numbers.json";
import * as currencies from "cldr-data/main/de/currencies.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';// To load the culture based first day of week

//21.
registerLicense(
  "ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5UdEBjWn5edHRWQ2Rd"
);

//import './assets/main.css'
import "./app/assets/main-dark.css";

/*
import "@syncfusion/ej2/material-dark.css";
*/

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData, currencies);
//setCulture("de-DE");
setCulture("de");
//setCulture("de-DE");
// To set currency code globally
setCurrencyCode("EUR");

const appStart = createApp(AppStart);
const appError = createApp(AppError);

/*
const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "dark",
  },
});
*/
// Registering Syncfusion license key

//app.use(VueCookies);

/*
app.use(createPinia());
app.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});

app.use(vuetify);
app.use(router);
*/

/*
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
*/

appStart.mount("#app");

function p_error(r: any) {
  console.log(r);
  appStart.unmount();
  appError.mount("#app");
}

apiAuth
  .init()
  .then((resp) => {
    if (!resp.successful) {
      console.log(resp.message);
      window.onload = function () {
        p_error(resp.message);
      };
      return;
    }
    const keycloakConfig = resp.keycloak;

    const app = createApp(App);
    app.use(createPinia());
    const config = useAccountStore();
    config.setInstallation(resp);

    //console.log(resp);

    if (keycloakConfig.active && resp.shopId !== "eVB_Aktivierung") {
      const initOptions: KeycloakConfig = {
        url: keycloakConfig.keycloakUrl || "",
        realm: keycloakConfig.realm || "",
        clientId: keycloakConfig.clientId || "",
      };

      // Vue.config.productionTip = false

      const keycloak = new Keycloak(initOptions);
      apiAuth.setKeycloak(keycloak);

      keycloak
        .init({
          onLoad: "check-sso",
          silentCheckSsoRedirectUri:
            window.location.origin + "/silent-check-sso.html",
          //onLoad: "login-required",
          checkLoginIframe: false,
        })
        .then(async (auth) => {
          config.authenticated = keycloak.authenticated === true;
          if (auth) {
            await apiAuth.authenticatePrivate();
          }
          /*
          keycloak.onAuthSuccess = function () {
            console.log("onAuthSuccess");
            config.authenticated = true;
            if (keycloak.token) apiService.token = keycloak.token;
            alert(keycloak.token);
          };
          keycloak.onAuthLogout = function () {
            console.log("onAuthLogout");
            config.authenticated = false;
            apiService.token = "";
          };
*/

          const vuetify = createVuetify({
            components,
            directives,
            theme: {
              defaultTheme: "dark",
            },
          });

          app.use(vuetify);
          app.use(router);

          appStart.unmount();
          app.mount("#app");
        })
        .catch((e) => {
          console.log(e);
          appStart.unmount();
          appError.mount("#app");
        });
    } else {
      const vuetify = createVuetify({
        components,
        directives,
        theme: {
          defaultTheme: "dark",
        },
      });

      app.use(vuetify);
      app.use(router);

      appStart.unmount();
      app.mount("#app");
    }
  })
  .catch((r) => {
    p_error(r);
  });
