export enum CarType {
  NoLimited = "NoLimited",
  PassengerCar = "PassengerCar",
  Trailer = "Trailer",
  Truck = "Truck",
  Motorcycle = "Motorcycle",
}

export function carTypeName(value?: string) {
  switch (value) {
    case CarType.PassengerCar:
      return "Pkw";
    case CarType.Trailer:
      return "Anhänger";
    case CarType.NoLimited:
      return "Offene";
    case CarType.Truck:
      return "Lkw";
  }
  return value ?? "";
}


