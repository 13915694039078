export enum SogButtonKind{
  Default,
  Back,
  Ok,
  Cancel,
  Save,
  Edit,
  Search,
  Refresh,
  FilterRemove
}
