export enum TerritoryType {
  Germany = "Germany",
  Eu = "Eu",
}
export function territoryTypeName(value?: string) {
  switch (value) {
    case TerritoryType.Germany:
      return "D";
    case TerritoryType.Eu:
      return "IVK";
  }
  return value;
}
