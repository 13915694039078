enum PersonDetailsId {
  CreditLimit = "CreditLimit",
  CashBookId = "CashBookId",
  OrderTypeId = "OrderTypeId",
  OrderEmail = "OrderEmail",
  InvoiceAddressId = "InvoiceAddressId",
  InvoiceDueDate = "InvoiceDueDate",
  OrderSchedule = "OrderSchedule",
  OrderScheduleNext = "OrderScheduleNext",
  OrderMail = "OrderMail",
  WithoutCreditLimit = "WithoutCreditLimit",
  InterneVermerkeVersicherung = "InterneVermerkeVersicherung",
  VUNummer = "VUNummer",
  CustomerNumber = "CustomerNumber",
  CustomerType = "CustomerType",
  AgentFrom = "AgentFrom",
  AgentTill = "AgentTill",
  AutomaticCancelationInvoicing = "AutomaticCancelationInvoicing",
  LossAdjusterManufacturerIds = "LossAdjusterManufacturerIds",
  ZollPolicyNumberPrefix = "ZollPolicyNumberPrefix",
  Iban = "IBAN",
  Bic = "BIC",
  Bank = "Bank",
  InvoiceCircle = "InvoiceCircle",
  OrderingBlocking = "OrderingBlocking",
  EvbActivationDefaultDuration = "EvbActivationDefaultDuration",
  EvbActivationMaxDuration = "EvbActivationMaxDuration",
}

export default PersonDetailsId;
