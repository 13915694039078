import {Api} from "@/shared/services/api";
import {useAccountStore} from "@/stores/accountStore";
import Person from "@/shared/model/person";
import Address from "@/shared/model/Address";
import InsuranceActivate from "@/shared/model/InsuranceActivate";
import Insurance from "@/shared/model/Insurance";

export class ApiInsurance extends Api {
  async checkActivationCode(code: string) {
    const config = useAccountStore();
    const request = {
      InstallationId: config.installation.installationId,
      ShopId: config.installation.shopId,
      ActivationCode: code,
    };
    const resp = await this.post("/ActivationCodeCheckRequest", request);
    if (resp.successful) {
      Api.token = resp.loginToken;

      return {
        activated: resp.activated,
        insuranceNumber: resp.insuranceNumber,
        id: resp.id,
      };
    }
  }

  async InsuranceActivate(
    activationPerson: Person,
    activationAddress: Address,
    insuranceIds: string[],
    duration: number
  ): Promise<InsuranceActivate[]> {
    const resp = await this.post("InsuranceActivateRequest", {
      InsuranceIds: insuranceIds,
      Person: activationPerson,
      Address: activationAddress,
      ActivationDuration: duration,
    });
    if (resp.successful && resp.items) {
      return resp.items;
    }
    return [];
  }

  async initPdf(
    ids: string[],
    policyHolder = true,
    offsetX = 0,
    offsetY = 0,
    currentYear = true
  ): Promise<string | undefined> {
    try {
      const request = {
        insuranceIds: ids,
        policyHolder: policyHolder,
        offsetX: offsetX,
        offsetY: offsetY,
        currentYear: currentYear,
      };
      const resp = await this.post("/InsurancePolicyInitPdfRequest", request);
      if (resp.successful && resp.id) {
        return resp.id;
      }
      return undefined;
    } finally {
    }
  }

  async load(id: string): Promise<{ insurance: Insurance } | undefined> {
    const request = {
      insuranceId: id,
    };
    const resp = await this.post("/InsuranceLoadRequest", request);
    if (resp.successful && resp.insurance) {
      return {insurance: resp.insurance};
    }
    return undefined;
  }

  public async addTempFile(fileId: string, insuranceId?: string, name?: string, filename?: string): Promise<boolean | undefined> {
    const resp = await this.post("InsuranceAddTempFileRequest", {
      tempFile: fileId,
      refId: insuranceId,
      fileName: filename,
      name: name
    });
    return resp.successful;
  }

  public async insuranceBlankLink(ids: string[], prefix?: string, startNumber?: number, digitCount?: number, manufacturerId?: string): Promise<string | undefined> {
    const resp = await this.post("InsuranceBlankLinkRequest", {
      prefix: prefix,
      startNumber: startNumber,
      digitCount: digitCount,
      insuranceIds: ids,
      manufacturerId: manufacturerId
    });
    if (resp.successful && resp.id) {
      return resp.id;
    }
    return undefined;
  }

  public async insuranceBlankLinkRemove(ids: string[]): Promise<string | undefined> {
    const resp = await this.post("InsuranceBlankLinkRemoveRequest", {
      insuranceIds: ids,
    });
    if (resp.successful && resp.id) {
      return resp.id;
    }
    return undefined;
  }

  public async insuranceCanclel(id: string): Promise<boolean | undefined> {
    const resp = await this.post("InsuranceCancelationInsertRequest", {
      insuranceIds: [id],
    });
    return resp.successful;
  }

  async list(ids: string[]): Promise<Insurance[] | undefined> {
    const request = {
      ids: ids,
    };
    const resp = await this.post("/InsuranceList", request);
    if (resp.successful && resp.result) {
      return resp.result;
    }
    return undefined;
  }

}

const apiInsurance = new ApiInsurance();

export default apiInsurance;
