import {Api} from "@/shared/services/api";
import GdvAccess from "@/shared/model/GdvAccess";

export class ApiGdv extends Api {
    public async list():Promise<GdvAccess[]| undefined>{

        const request = {

        };
        const resp = await this.post("/GdvAccessListRequest", request);
        if(resp.successful && resp.result) {
            return resp.result;
        }

        return undefined;

    }
    public async update(gdvAccess: GdvAccess): Promise<boolean | undefined> {
        const request = {
            gdvAccess: gdvAccess
        };
        const resp = await this.post("/GdvAccessUpdateRequest", request);
        return resp.successful
    }

    public async changePassword(id: string | undefined, newPin: string ): Promise< string | undefined> {
        const request = {
            id: id,
            newPin: newPin
        }
        const resp = await this.post("/GdvAccessChangePinRequest", request);

        if(resp.successful){
            return resp.message;
        }
        return undefined;

    }
}
const apiGdv = new ApiGdv();
export default apiGdv;
