import HistoryDetails from "@/shared/model/HistoryDetails";

export default class HistoryDetailsOrderHeadInfo extends HistoryDetails {
  id?: string
  personId?: string
  orderNumber?: string
  insuranceAmount?: number
  orderAmount?: number
  paid?: boolean
  orderedByPersonId?: string
  orderedDate?: Date
  statusId?: string
  supplierId?: string
  memo?: string
  orderTypeId?: string
  templateId?: string
  addressLine1?: string
  addressLine2?: string
  addressStreet?: string
  addressHousNumber?: string
  addressCity?: string
  addressPostalCode?: string
  addressCountryCode?: string
  refOrderHeadId?: string
  orderedByPersonName?: string
  supplierName?: string
  personName?: string
  invoiceCircle?: string
}

