import {Api} from "@/shared/services/api";
import InsuranceExportBlank, {InsuranceExportBlankStatus} from "@/shared/model/InsuranceExportBlank";

export class ApiInsuranceExportBlank extends Api {
  async setStatus(id: string | undefined, status?: InsuranceExportBlankStatus): Promise<boolean | undefined> {
    const request = {
      id: id,
      status: status ?? ""
    };
    const resp = await this.post("/InsuranceExportBlankSetStatusRequest", request);
    return resp.successful;
  }

  public async addTempFile(fileId: string, insuranceId?: string, name?: string, filename?: string): Promise<boolean | undefined> {
    const resp = await this.post("InsuranceExportBlankAddTempFileRequest", {
      tempFile: fileId,
      refId: insuranceId,
      fileName: filename,
      name: name
    });
    return resp.successful;
  }


  async load(id: string): Promise<InsuranceExportBlank | undefined> {
    const request = {id: id}
    const resp = await this.post("/InsuranceExportBlankLoadRequest", request)
    return resp.insuranceExportBlank
  }
}

const apiInsuranceExportBlank = new ApiInsuranceExportBlank();

export default apiInsuranceExportBlank;

/*
  async InsuranceActivate(
  ): Promise<|> {
    try {
      const request = {
        loginName: loginName,
        personId: personId,
        actionPersonId: actionPersonId,
      };
      const resp = await this.post("/AccountInsertRequest", request);
      if (resp.successful) {
        return true;
      }
      return undefined;
    } finally {
    }
}
* */
