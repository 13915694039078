import ShoppingCartItem from "@/shared/model/shoppingCartItem";
import Person from "@/shared/model/person";
import Address from "@/shared/model/Address";
import ProductPrice from "@/shared/model/productPrice";
import Product from "@/shared/model/product";
import {ProductType} from "@/shared/model/productType";

export class ShoppingCart {
  items: ShoppingCartItem[] = [];
  personId = "";
  supplierId? = "";
  activation = false;
  activationDuration = 90;
  activationPerson = {Person: new Person(), Address: new Address()};
  invoicePerson = {Person: new Person(), Address: new Address()};
  saveInvoicePerson = false;
  saveActivationPerson = false;

  productPrices: ProductPrice[] = [];
  products: Product[] = [];
  invoiceCircle?: string;
  isSale = false;

  currentItem: {
    baseProductType?: ProductType,
    baseProducts: Product[],
    productPrices: ProductPrice[],
    shoppingCartItem: ShoppingCartItem
  } = {baseProducts: [], productPrices: [], shoppingCartItem: new ShoppingCartItem()};

  invoiceType: string | undefined;

  get isInvoiced(): boolean {
    const t_invoiceType = this.invoiceType;
    return t_invoiceType !== undefined && t_invoiceType.indexOf("Invoice") > -1;
  }


  public clearCurrentItem() {
    this.currentItem = {baseProducts: [], productPrices: [], shoppingCartItem: new ShoppingCartItem()};
  }

  get id(): string {
    return this.personId;
  }
}
