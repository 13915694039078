import {Api} from "@/shared/services/api";

export class ApiLossAdjuster extends Api {
  async upload(id: string, fileName: string, cutOfDay: Date): Promise<boolean | unknown> {
    try {
      const request = {
        id: id,
        fileName: fileName,
        cutOfDay: cutOfDay
      };
      const resp = await this.post("/LossAdjusterUploadRequest", request);
      if (resp.successful) {
        return true;
      }
      return undefined;
    } finally {
    }
  }

  public async initDownloadFile(id: string): Promise<string | undefined> {
    const request = {
      id: id
    };
    const resp = await this.post("/LossAdjusterInitDownloadRequest", request);
    if (resp.successful && resp.id) {
      return resp.id;
    }
    return undefined;
  }

}

const apiLossAdjuster = new ApiLossAdjuster();

export default apiLossAdjuster;

/*
  async InsuranceActivate(
  ): Promise<|> {
    try {
      const request = {
        loginName: loginName,
        personId: personId,
        actionPersonId: actionPersonId,
      };
      const resp = await this.post("/AccountInsertRequest", request);
      if (resp.successful) {
        return true;
      }
      return undefined;
    } finally {
    }
}
* */
