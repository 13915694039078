import {RouteRecordRaw} from "vue-router";
import InsuranceCancelationListPage from "@/modules/InsuranceCancelation/pages/InsuranceCancelationListPage.vue";
import ManagementPage from "@/modules/InsuranceCancelation/pages/ManagementPage.vue";
import InsuranceCancelationDetailsPage from "@/modules/InsuranceCancelation/pages/InsuranceCancelationDetailsPage.vue";



const insuranceCancelationRoutes: Array<RouteRecordRaw> = [
    {
        path: "/insurancecancelation",
        children: [
            {
                path: "list",
                component: InsuranceCancelationListPage,
            },
            {
                path: "management",
                component: ManagementPage
            },
            {
                path: 'details/:id',
                component: InsuranceCancelationDetailsPage,
                props: true,
            },
        ],
    },
];

export default insuranceCancelationRoutes;