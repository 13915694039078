import {MaybeRefOrGetter, ref, toValue, watchEffect} from "vue";
import {ProgressInfo} from "@/shared/model/ProgressInfo";
import progressService from "@/shared/services/ProgressService";
import notificationService from "@/shared/services/NotificationService";

export function useProgressInfo(id:MaybeRefOrGetter<string|undefined>,last:MaybeRefOrGetter<boolean>) {
  const _progressInfo = ref<ProgressInfo | undefined>(undefined)
  const _id = ref<string | undefined>(undefined);

  async function onRefresh(group: string, progressInfo: ProgressInfo) {
    if(_id.value !== progressInfo.groupId)
      return
    _progressInfo.value = progressInfo
    if (progressInfo && progressInfo.finishDate) {
      if (!progressInfo.successful) {
        notificationService.error(progressInfo.resultMessage ?? "")
      }
      await progressService.removeFromGroup([progressInfo.groupId ?? ""], onRefresh)
    }
  }

  watchEffect(async () => {
      const t_id = toValue(id);
      if (_id.value && _id.value !== t_id) {
        await progressService.removeFromGroup([_id.value], onRefresh);
      }
      _id.value = t_id;
      if (t_id) {
        await progressService.addToGroup([t_id], toValue(last), onRefresh)
      }
    }
  )


  return {progressInfo: _progressInfo}
}
