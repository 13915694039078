import RechercheMessage from "@/shared/model/RechercheMessage";
import { Api } from "@/shared/services/api";
import copyTo from "@/shared/utils/CopyTo";

export class ApiVbRecherche extends Api {
  
   async  load(evb: string): Promise<RechercheMessage|undefined> {
    const request = {
      evb: evb,
    };    
    
    
    const resp = await this.post("/RechercheVbDetailsRequest", request);
    if (resp.successful && resp.rechercheMessage) {

      const res = new RechercheMessage();

      copyTo( resp.rechercheMessage, res);
      
      res.gdvMessageMode = resp.gdvMessageMode

      return res;
    }
    return undefined;
  }
}

const apiVbRecherche:ApiVbRecherche = new  ApiVbRecherche();

export default apiVbRecherche;

