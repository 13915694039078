import { Api } from "@/shared/services/api";

export class ApiUsNato extends Api {
  async upload(fileName:string): Promise<boolean|unknown> {
    try {
      const request = {
        fileName: fileName,
      };
      const resp = await this.post("/UsNatoUploadRequest", request);
      if (resp.successful) {
        return true;
      }
      return undefined;
    } finally {
    }
  }
}

const apiUsNato = new ApiUsNato();

export default apiUsNato;

/*
  async InsuranceActivate(
  ): Promise<|> {
    try {
      const request = {
        loginName: loginName,
        personId: personId,
        actionPersonId: actionPersonId,
      };
      const resp = await this.post("/AccountInsertRequest", request);
      if (resp.successful) {
        return true;
      }
      return undefined;
    } finally {
      this.hideSpinner();
    }
}
* */
