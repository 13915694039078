import {RouteRecordRaw} from "vue-router";
import OrderListView from "@/modules/Order/pages/OrderListPage.vue";
import OrderDetailsView from "@/modules/Order/pages/OrderDetailsPage.vue";

const orderRoutes: Array<RouteRecordRaw> = [
  {
    path: "/order",
    //component: OrderView,
    children: [
      {
        path: "details/:id?",
        name: "order",
        component: OrderDetailsView,
        props: true,
      },
      {
        path: ":sector",
        children: [
          {
            path: "list/:type/:filtertype?",
            component: OrderListView,
          },
          /*
                    {
                      path: '',
                      component: CrisisCenterHomeComponent
                    }
          */
        ],
      },
    ],
  },
];

export default orderRoutes;
