export default class Address {
  constructor() {
    this.countryCode = "DE";
  }
  id: string | undefined;
  personId: string | undefined;
  fullName: string | undefined;
  line1: string | undefined;
  street: string | undefined;
  houseNumber: number | undefined;
  houseNumberAddition: string | undefined;
  city: string | undefined;
  postalCode: string | undefined;
  countryCode: string | undefined;
  phoneNumber: string | undefined;
  countryName: string | undefined;
  fax: string | undefined;

  static createAddress():Address{
    const result = new Address();
    result.fullName = "";
    result.line1 = "";
    result.street = "";
    result.postalCode = ""
    result.countryCode = "DE"
    result.city = ""
    return result
  }
}
