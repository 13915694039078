import { Api } from "@/shared/services/api";
import { ShoppingCart } from "@/shared/model/shoppingCart";

export class ApiShoppingCart extends Api {
  async sale(shoppingCart: ShoppingCart): Promise<string | undefined> {
    const items: any[] = [];

    shoppingCart.items.map((t) => {
      const insuranceBlankNumbers:string[]=[]
      const insuranceIds:string[]=[]
      if(t.productPrice?.isStoreExportBlank){
        t.insuranceExportBlanks.map(t=>insuranceBlankNumbers.push(t.insuranceNumber??""))
      }
      else if (t.productPrice?.productTypeId === "ExportBlank"){
        t.insuranceExportBlanks.map(t=>insuranceBlankNumbers.push(t.blankNumber??""))
      }
      else if(t.productPrice?.isStore){
        t.insurances.map(t=>insuranceIds.push(t.id??""))
      }

      const item = {
        BasePriceId: t.productPrice?.id,
        Count: t.count,
        GrossPrice: t.grossPrice,
        insuranceBlankNumbers:insuranceBlankNumbers,
        insuranceIds:insuranceIds,
        prefix:t.prefix,
        startNumber:t.startNumber,
        digitCount:t.digitCount
      };
      items.push(item);
    });

    const resp = await this.post("ShoppingSaleRequest", {
      PersonId: shoppingCart.personId,
      supplierId: shoppingCart.supplierId,
      Items: items,
      invoiceCircle:shoppingCart.invoiceCircle
    });
    if (resp.successful && resp.id) {
      return resp.id;
    }
  }
}

const apiShoppingCart = new ApiShoppingCart();

export default apiShoppingCart;
