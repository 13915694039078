import { RouteRecordRaw } from "vue-router";
import ClaimsPage from "@/modules/Calims/ClaimsPage.vue";
import HomeView from "@/views/HomeView.vue";

const claimsRoutes: Array<RouteRecordRaw> = [
    {
        path: "/schaden",
        component: ClaimsPage,
        meta: {
            noAuth: true,
        },
    },
    {
        path: "/schadenonline",
        name: "schadenonline",
        component: {},
        beforeEnter() {
            window.location.replace("https://form.jotform.com/233092298380359")
        },
        meta: {
            noAuth: true,
        },
    },
];

export default claimsRoutes;
