import { Api } from "@/shared/services/api";
import { useAccountStore } from "@/stores/accountStore";
import { User } from "@/shared/model/User";
import copyTo from "@/shared/utils/CopyTo";
import notificationService from "@/shared/services/NotificationService";
import apiOrganisationStructure from "@/modules/OrganisationStructure/api";
import Keycloak from "keycloak-js";
import router from "@/app/routes";

export class ApiAuth extends Api {
  constructor() {
    super();
    Api.logout = this.logout;
  }
  private static keycloak: Keycloak | undefined;
  private static pingIntervalId: number|undefined;

  setKeycloak(keycloak: Keycloak) {
    keycloak.onAuthSuccess = async function () {
      const config = useAccountStore();
      config.authenticated = true;
      if (keycloak.token) Api.token = keycloak.token;
      await apiAuth.authenticate();
      //await apiService.authenticatePrivate();
    };
    keycloak.onAuthLogout = function () {
      const config = useAccountStore();
      config.authenticated = false;
      Api.token = "";
      clearInterval(ApiAuth.pingIntervalId)
      ApiAuth.pingIntervalId = undefined
    };

    ApiAuth.keycloak = keycloak;
  }

  private async ping() {
    const request = {};
    await this.post("/PingRequest", request,false);
  }

  private async authenticate() {
    return;
    const config = useAccountStore();
    const request = {
      InstallationId: config.installation.installationId,
      ShopId: config.installation.shopId,
    };
    const resp = await this.post("/AuthenticateRequest", request, false);
    if (resp.successful) {
//      ApiAuth.pingIntervalId = setInterval(this.ping,1000*60*5)
      console.log("!!!")
      ApiAuth.pingIntervalId = setInterval(this.ping,1000)
      const user = new User();
      copyTo(resp, user);
      config.user = user;

      apiOrganisationStructure.loadPerson(user.personId).then((resp) => {
        if (resp) {
          config.person = resp.person;
          config.addresses = resp.addresses;
          config.details = resp.details;
        }
      });
      apiOrganisationStructure.loadPerson(user.actionPersonId).then((resp) => {
        //        if (person) config.actionPerson = person;
        if (resp) config.actionPerson = resp.person;
      });

      return {
        customerId: resp.customerId,
        personId: resp.personId,
      };
    } else {
      notificationService.errorThen(resp.message, () => {
        this.logout();
      });
    }
  }
  async authenticatePrivate() {
    const config = useAccountStore();
    const request = {
      InstallationId: config.installation.installationId,
      ShopId: config.installation.shopId,
    };
    const resp = await this.post("/AuthenticateRequest", request, false);
    if (resp.successful) {

      ApiAuth.pingIntervalId = setInterval(async () => {
        await apiAuth.ping()
      },1000*60*5)

      const user = new User();
      copyTo(resp, user);
      config.user = user;

      let personResp = await apiOrganisationStructure.loadPerson(user.personId);
      if (personResp) {
        config.person = personResp.person;
        config.addresses = personResp.addresses;
        config.details = personResp.details;
      }

      personResp = await apiOrganisationStructure.loadPerson(
        user.actionPersonId
      );
      if (personResp) config.actionPerson = personResp.person;
    } else {
      alert(resp.message)
      await this.logout();
    }
  }

  async logout() {
    console.log("logout")
    await ApiAuth.keycloak?.logout();
    await router.push("/")
  }

  async login(options: any) {
    if (ApiAuth.keycloak) {
      if (options) await ApiAuth.keycloak.login(options);
      else await ApiAuth.keycloak.login();
      await this.authenticatePrivate();
    }
  }

  isAuthenticated() {
    return ApiAuth.keycloak?.authenticated;
  }

  async init() {
    const request = { Hostname: document.location.hostname };
    const resp = await this.post("/InstallationRequest", request, false);
    if (resp.successful) {
/*
      const result = {
        installationId: resp.installationId,
        shopId: resp.shopId,
        shortName: resp.shortName,
        keycloak: {
          keycloakUrl: resp.keycloak.keycloakUrl,
          realm: resp.keycloak.realm,
          clientId: resp.keycloak.clientId,
        },
      };
*/
      return resp;
    }
  }
}

const apiAuth = new ApiAuth();

export default apiAuth;
