export default class Payment {
  id?: string;
  orderId?: string;
  date?: Date;
  personId?: string;
  personName?: string;
  paymentType?: string;
  paymentTypeId?: string;
  bank?: string;
  memo?: string;
}
