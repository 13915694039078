export enum ProductType {
  Kzk = "Kzk",
  Zoll = "Zoll",
  Addition = "Addition",
  Cancelation = "Cancelation",
  SubsequentTax = "SubsequentTax",
  CancelationCorrection = "CancelationCorrection",
  SubsequentBillingInsurance = "SubsequentBillingInsurance",
  SubsequentBillingCancelation = "SubsequentBillingCancelation",
  SubsequentBillingTax = "SubsequentBillingTax",
  SubsequentBillingTaxCancelation = "SubsequentBillingTaxCancelation",
  InsuranceProductChange = "InsuranceProductChange",
  Tz = "Tz",
  Carplate = "Carplate",
  None = "None",
  SubsequentTaxCancelation = "SubsequentTaxCancelation",
  ExportBlank = "ExportBlank",
}

export function productTypeName(value?: string) {
  switch (value) {
    case ProductType.Kzk:
      return "Kurzzeitkennzeichen";
    case ProductType.Zoll:
      return "Ausfuhrkennzeichen";
    case ProductType.Tz:
      return "Tageszulassung";
    case ProductType.ExportBlank:
      return "Ausfuhrversicherungsformular";
    case undefined:
      return ""
  }
  return value;
}

export function isInsurance(value?: string) {
  switch (value) {
    case ProductType.Kzk:
    case ProductType.Zoll:
    case ProductType.Tz:
      return true;
  }
  return false;
}
