import {RouteRecordRaw} from "vue-router";
import OrderList from "@/modules/Order/components/OrderListComponent.vue";
import ShoppingCartView from "@/modules/ShoppingCart/pages/ShoppingCartPage.vue";
import EditShoppingCartItemComponent from "@/modules/ShoppingCart/components/editShoppingCartItemComponent.vue";
import ShoppingCartListComponent from "@/modules/ShoppingCart/components/shoppingCartListComponent.vue";
import CashPointComponent from "@/modules/ShoppingCart/components/cashPointComponent.vue";
import SingleProductPage from "@/modules/ShoppingCart/pages/SingleProductPage.vue";

const shoppingCartRoutes: Array<RouteRecordRaw> = [
  // ****************** order
  {
    path: "/shoppingcart",
    component: ShoppingCartView,
    children: [
      /*
      {
        path: 'details/:id',
        component: OrderDetailsComponent,
      },
*/
      {
        path: ":sector",
        children: [
          {
            path: "add",
            component: EditShoppingCartItemComponent,
          },
          {
            path: "list",
            component: ShoppingCartListComponent,
          },
          {
            path: "cashpoint",
            component: CashPointComponent,
          },
          {
            path: "invoice",
            component: OrderList,
          },
          /*
                    {
                      path: '',
                      component: CrisisCenterHomeComponent
                    }
          */
        ],
      },
    ],
  }, {
    path: "/shoppingcartsingleproduct/:sector/:page?",
    component: SingleProductPage,
    props: true,
  }
];

export default shoppingCartRoutes;
