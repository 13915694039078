import {Api} from "@/shared/services/api";

export class ApiTest extends Api {
  async initExport(periodId: string, partnershipIds: string[], voucher: boolean, csv: boolean, ftp: boolean): Promise<string | undefined> {
    if (!periodId) {
      return undefined
    }
    const resp = await this.post("WakamBordereauInitExport", {
      periodId: periodId,
      partnershipIds: partnershipIds,
      voucher: voucher,
      csv: csv,
      ftp: ftp
    });
    if (resp.successful && resp.id) {
      return resp.id;
    }
  }

  async lock(periodId: string): Promise<boolean | undefined> {
    return undefined

    if (!periodId) {
      return undefined
    }
    const resp = await this.post("WakamBordereauLockPeriod", {
      id: periodId,
    });
    return resp.successful;
  }

  async unlock(periodId: string): Promise<boolean | undefined> {
    return undefined
    if (!periodId) {
      return undefined
    }
    const resp = await this.post("WakamBordereauUnlockPeriod", {
      id: periodId,
    });
    return resp.successful;
  }

  async addTempFile(fileId: string, insuranceId: string, name: string, filename: string): Promise<boolean | undefined> {
    const resp = await this.post("AddTempFileRequest", {
      tempFile: fileId,
      refId: insuranceId,
      fileName: filename,
      name: name
    });
    return resp.successful;
  }

  async cancelation(id: string): Promise<any | undefined> {
    const resp = await this.post("InsuranceCancelationLoadRequest", {
      id: id,
    });
    return resp;
  }


}

const apiTest = new ApiTest();

export default apiTest;

