export default function copyTo(source: any, target: any) {
  if (target && source) {
    Object.keys(target).forEach((key) => {
      if (source[key] !== undefined) {
        if(Array.isArray(source[key])){
          target[key] = [...source[key]];
        }
        else
          target[key] = source[key];
      }
    });
  }
}
