import {onMounted, ref} from "vue";
import {createSpinner, hideSpinner, showSpinner} from "@syncfusion/ej2-vue-popups";

export function useSpinner(id?:string){
  const _id = ref("page")
  if(id){
    _id.value = id
  }

  function getElement(){
    return document.getElementById(_id.value)
  }
  function show(){
    const el = getElement();
    if(el) {
      showSpinner(el)
    }
  }
  function hide(){
    const el = getElement();
    if(el) {
      hideSpinner( el);
    }
  }
  onMounted( () => {
    const el = getElement();
    if(el) {
      createSpinner({
        target: el
//      cssClass : 'e-spin-overlay',
      });
    }
    //show();
  })

  return {show,hide}
}
