<template>
  <div
    id="myapp"
    class="content"
  >
    <SogDialog
      ref="dialog"
      target="#app"
    />
    <ejs-message
      id="msg"
      :content="notification.message"
      :visible="messageVisible"
      :severity="messageType"
      :show-close-icon="true"
      class="notification"
    />
    <ejs-dialog
      ref="spinner"
      target="#app"
      is-modal="true"
      :visible="false"
      height="auto"
    />
    <!--
    <SogDialog target="#app" ref="spinner" dialog-buttons="SaveCancel" header="test"/>
-->
    <!--
    <Navigation />
-->
    <AppBarComponent @menu-click="menuClick" />
    <div>
      <ejs-sidebar
        id="sideTree"
        ref="sidebarTreeviewInstance"
        class="sidebar-treeview"
        width="220px"
        target=".main-sidebar-content"
        media-query="(min-width: 1440px)"
        @change="onSidebarChange"
        @open="onSidebarChange"
        @close="onSidebarChange"
      >
        <MenuSideBarComponent @navigate="onMenuNavigate" />
      </ejs-sidebar>
    </div>
    <div
      class="main-sidebar-content"
    >
      <main-component />
    </div>
    <div
      id="footer"
      style="width: 100%"
    >
      <MyFooter />
    </div>
  </div>
</template>
<script>
import {useCookies} from "vue3-cookies";
import MyFooter from "@/modules/Footer/components/Footer.vue";
import {useAccountStore} from "@/stores/accountStore";
import SogDialog from "@/shared/ui/SogDialog.vue";
import {MessageComponent} from "@syncfusion/ej2-vue-notifications";
import notificationService, {NotificationType} from "@/shared/services/NotificationService";
import {
  ColumnChooser, ColumnMenu, CommandColumn, Edit,
  ExcelExport,
  Filter,
  Page, Reorder,
  Resize,
  Sort,
  Toolbar
} from "@syncfusion/ej2-vue-grids";
import {

  DialogComponent
} from "@syncfusion/ej2-vue-popups";
import {useNavigationStore} from "@/modules/Navigation/store";
import {storeToRefs} from "pinia";
import {ref} from "vue";
import AppBarComponent from "@/modules/Navigation/components/AppBarComponent.vue";
import {enableRipple} from '@syncfusion/ej2-base';
import {SidebarComponent} from '@syncfusion/ej2-vue-navigations';
import MenuSideBarComponent from "@/modules/Navigation/components/MenuSideBarComponent.vue";
import {useDisplay} from "vuetify";
import MainComponent from "@/app/components/MainComponent.vue";

const {cookies} = useCookies();


if (!cookies.isKey("dark"))
  cookies.set("dark", true)

/*
const dark = cookies.get("dark")
*/
const dark = true
/*
const a = ref(dark?"main-dark":"main")
*/
import(`./assets/${dark ? "main-dark" : "main"}.css`);


export default {
  name: "App",
  components: {
    MainComponent,
    MenuSideBarComponent,

    AppBarComponent,
    SogDialog,
    MyFooter,
    "ejs-dialog": DialogComponent,
    'ejs-message': MessageComponent,
    "ejs-sidebar": SidebarComponent
  },
  provide: {
    grid: [Page, Sort, Resize, Filter, Toolbar, ColumnChooser, ExcelExport, ColumnMenu, Reorder, CommandColumn, Edit]
  },
  setup() {
    const config = useAccountStore()
    const navigation = useNavigationStore();
    document.title = config.installation.shortName
    const dialog = ref(null)
    const dialogError = ref(null)
    const spinner = ref < DialogComponent | null > (null)
    const buttons = "YesNo";
    const messageVisible = ref(false)
    const messageType = ref("")
    const interval = ref(undefined)
    const {mobile} = useDisplay()


    enableRipple(true);


    const {spinnerVisible, notificationVisible, notification} = storeToRefs(navigation)

    function menuClick() {
      const sidebar = document.getElementById("sideTree").ej2_instances[0];
      sidebar.toggle();
    }

    function onMenuNavigate() {

      if (mobile.value) {
        const sidebar = document.getElementById("sideTree").ej2_instances[0];
        sidebar.hide();
      }
    }

    function onSidebarChange(args) {
      const store = useNavigationStore();
      if (args.name === "open") {
        store.menuSidebarVisible = true;
      }
      if (args.name === "close") {
        store.menuSidebarVisible = false;
      }
    }

    // ...
    return {
      dialog,
      dialogError,
      spinnerVisible,
      spinner,
      buttons,
      messageVisible,
      notificationVisible,
      notification,
      messageType,
      interval,
      menuClick,
      onMenuNavigate,
      onSidebarChange
    }
  },
  watch: {
    spinnerVisible(newValue) {
      if (newValue) {
        showSpinner(document.getElementById("myapp"));
        //this.spinner.show()
      } else {
        hideSpinner(document.getElementById("myapp"));
        //this.spinner.hide()
      }

//      this.spinner.show()
    },
    notificationVisible(newValue) {
      if (newValue) {
        if (this.interval) {
          clearInterval(this.interval)
          this.interval = undefined
        }
        this.messageVisible = true
        switch (this.notification.type) {
          case NotificationType.Info:
            this.messageType = "Info";
            break
          case NotificationType.Error:
            this.messageType = "Error";
            break
          case NotificationType.Warn:
            this.messageType = "Warning";
            break
          case NotificationType.Success:
            this.messageType = "Success";
            break
          default:
            this.messageType = "Normal"
            break
        }
        this.notificationVisible = false;
        this.interval = setInterval(() => {
          this.messageVisible = false
        }, 5000)
      }
    }
  },

  mounted() {
    /*
        notificationService.initDialog(this.dialog,this.dialogError)
    */
    /*
        createSpinner({
          // Specify the target for the spinner to show
          target: document.getElementById("myapp"),
    //      cssClass : 'e-spin-overlay',
        });
    */
    notificationService.initDialog(this.dialog)
  }
}
</script>
<style>
.sidebar-treeview {
  margin-right: 10px;
}

.content {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.notification {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99
}

.main-sidebar-content {
  /*
  background: red;
  */
  min-height: calc(100% - 300px);
}

div#myapp {
  /*
  background: #22d3ee;
  */
}

@media (max-width: 1025px) {
  .main-sidebar-content {
    width: 99%;
  }
}

@media (min-width: 1025px) {
  .main-sidebar-content {
    width: 95%;
  }
}
</style>
