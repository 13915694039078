import {defineStore} from "pinia";
import {ref} from "vue";
import {
  Notification,
  NotificationType,
} from "@/shared/services/NotificationService";
import {useRouter} from "vue-router";
import {IdType} from "@/shared/model/idType";
import {computed} from "vue";
import Insurance from "@/shared/model/Insurance";
import {useInsuranceStore} from "@/modules/Insurance/store";

export const useNavigationStore = defineStore("navigation", () => {
  const currentOrderNumber = ref<string | undefined>(undefined);
  const currentPersonId = ref<string | undefined>(undefined);
  const currentPersonName = ref<string | undefined>(undefined);
  const currentInsuranceId = ref<string | undefined>(undefined);
  const currentInsuranceNumber = ref<string | undefined>(undefined);
  const currentVehicleRegistrationId = ref<string | undefined>(undefined);
  const currentInsuranceCancelationId = ref<string | undefined>(undefined);
  const currentGdvAccessId = ref<string | undefined>(undefined);
  const menuSidebarVisible = ref<boolean>(false);
  const _router = useRouter();
  const _params = ref<string | undefined>(undefined);
  const params = computed(() => {
    return _params.value
  })

  const notification = ref<Notification>({
    type: NotificationType.Info,
    message: "",
  });
  const notificationVisible = ref(false);

  function showNotification(value: Notification) {
    notification.value = value;
    notificationVisible.value = true;
  }

  async function showObject(idType?: IdType, id?: string) {
    switch (idType) {
      case IdType.Order:
        await _router.push("/order/details/" + id);
        break;
      case IdType.Insurance:
        await _router.push("/insurance/details/" + id);
        break;
      case IdType.VehicleRegistration:
        await _router.push("/vehicleregistration/details/" + id);
        break;
      case IdType.Evb:
        await _router.push("/vbrecherche/details/" + id);
        break;
      case IdType.InsuranceExportBlank:
        await _router.push("/insuranceexportblank/details/" + id);
        break;
      case IdType.InsuranceCancelation:
        await _router.push("/insurancecancelation/details/" + id);
        break;
    }
  }

  async function showInsuranceList(idType: IdType, id?: string, params?: string) {
    _params.value = params
    switch (idType) {
      case IdType.Order:
        await _router.push("/insurance/list/order/" + id)
        return;
      default:
        await _router.push("/insurance/list")
    }
  }

  async function showPrintExportInsurance(insurances: Insurance[]) {
    const t_insuranceStore = useInsuranceStore();
    t_insuranceStore.printExportInsurancePage.insurances.length = 0;
    t_insuranceStore.printExportInsurancePage.insurances.push(...insurances);
    await _router.push("/insurance/print/export")
  }

  function back() {
    _router.back()
  }

  return {
    currentGdvAccessId,
    currentOrderNumber,
    currentPersonId,
    currentPersonName,
    currentInsuranceId,
    currentInsuranceNumber,
    currentVehicleRegistrationId,
    currentInsuranceCancelationId,
    notification,
    notificationVisible,
    showNotification,
    menuSidebarVisible,
    back,
    showObject,
    showInsuranceList,
    params,
    showPrintExportInsurance
  };
});
