import {useNavigationStore} from "@/modules/Navigation/store";

export enum NotificationType {
  Info = "info",
  Warn = "warn",
  Success = "success",
  Error = "error",
}

export interface Notification {
  message: string;
  type: NotificationType;
}

class NotificationService {
  private _dialog: any;

  private showNotification(notification: Notification): void {
    let cssClass = "";
    let title = "";
    //const type = "";
    switch (notification.type) {
      case NotificationType.Error: {
        cssClass = "e-toast-danger";
        title = "Error";
        break;
      }
      case NotificationType.Info: {
        cssClass = "e-toast-info";
        title = "Information";
        break;
      }
      case NotificationType.Warn: {
        cssClass = "e-toast-warning";
        title = "Warning";
        break;
      }
      case NotificationType.Success: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        cssClass = "e-toast-success";
        title = "Success";
        break;
      }
    }
    const navigationStore = useNavigationStore();
    navigationStore.showNotification(notification);
    /*
    Vue.notify({
      // group: 'main',
      title: title,
      text: notification.message,
      type: notification.type,
    });
*/

    console.log(title + " " + notification.message);

    /*
    if (this._notification) {
      this._notification.show(notification)
    }
*/
  }

  public info(message: string): void {
    this.showNotification({type: NotificationType.Info, message: message});
  }

  public warn(message: string): void {
    this.showNotification({type: NotificationType.Warn, message: message});
  }

  public success(message: string): void {
    this.showNotification({type: NotificationType.Success, message: message});
  }

  public errorThen(
    message: string,
    onOk: (() => void) | undefined = undefined
  ): void {
    this.showNotification({type: NotificationType.Error, message: message});
    if (this._dialog) {
      this._dialog.showOk(
        "",
        "<h4 class='e-warning'>Fehler</h4>" + message,
        onOk
      );
    }
  }

  public error(
    message: string,
    error: any = undefined,
  ): void {
    console.log(error)
    let t_message = message
    if (error) {
      t_message += " " + error
    }

    this.showNotification({type: NotificationType.Error, message: t_message});
    if (this._dialog) {
      this._dialog.showOk(
        "",
        "<h4 class='e-warning'>Fehler</h4>" + message
      );
    }
  }


  public spinner(message: string): void {
    this.showNotification({type: NotificationType.Info, message: message});
    if (this._dialog) {
      this._dialog.showModal("", "<h4 class='e-warning'>Fehler</h4>" + message);
    }
  }

  public initDialog(dialog: any): void {
    this._dialog = dialog;
  }

  public showYesNo(title: string, content: string, onYes: () => void) {
    if (this._dialog) {
      this._dialog.showYesNo(title, content, onYes);
    }
  }
}

const notificationService = new NotificationService();

export default notificationService;
